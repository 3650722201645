// --- Buttons - Additional ---

// Removes horizontal padding from full-width buttons.
// The padding does nothing for the layout since the button's width is predefined, and it may break text alignment if the container is too narrow.

// Overrides for .button and .button-*
.button-padding-fix(@prefix) {
	.button,
	[class^="button-"],
	[class*=" button-"] {
		&.@{prefix}one-whole {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

// Overrides for .<prefix>-button and .<prefix>-button-*
// (Separate mixin because it does not apply to the default block outside of the media queries)
.button-padding-fix-prefixed(@prefix) {
	.@{prefix}button,
	[class^="@{prefix}button-"],
	[class*=" @{prefix}button-"] {
		&.one-whole {
			padding-left: 0;
			padding-right: 0;
		}

		&.@{prefix}one-whole {
			padding-left: 0;
			padding-right: 0;
		}
	}
}


.button-padding-fix(@empty);

@media @mediaQueryPalmOnly {
	.button-padding-fix(@prefixPalm);
	.button-padding-fix-prefixed(@prefixPalm);
}
@media @mediaQueryLapOnly {
	.button-padding-fix(@prefixLap);
	.button-padding-fix-prefixed(@prefixLap);
}
@media @mediaQueryDeskOnly {
	.button-padding-fix(@prefixDesk);
	.button-padding-fix-prefixed(@prefixDesk);
}