@import "../../../less/_variables/typography.less";
@import "../../../less/_variables/color-palette.less";
@import "../../../less/_variables/spacing.less";
@import "../../../less/_mixins/typography.less";
@import "../../../less/_mixins/general.less";

.table-container {
	width: 100%;
	overflow: scroll;
	background-color: @white;
}

.table {
	width: 100%;
	border-collapse: separate;

	&-sort-button {
		color: @black;
		padding: 0;
		margin-right: 8px;
	}

	&-header-cell {
		font: @fontFamilyStandard-theme-cosma;
		font-weight: 700;
		font-size: 14px;
		color: @black;
		line-height: 22px;
		white-space: nowrap;
		border-bottom: 2px solid @color-brand-theme-cosma;

		&--sticky {
			margin: 0;
			position: sticky;
			top: 0;
			z-index: 1;
			background: white;
		}

		&--with-shadow {
			box-shadow: 10px 4px 10px 2px rgba(0, 0, 0, 0.08);
		}

		&--sticky-flipped {
			position: sticky;
			z-index: 1;
			left: 0;
			background: white;
		}

		&-content,
		&-sort-button {
			display: flex;
			align-items: center;
		}

		&--flipped {
			border-bottom: none;
			border-right: 2px solid @color-brand-theme-cosma;
		}

		&--with-shadow-flipped {
			box-shadow: 4px 10px 10px 2px rgba(0, 0, 0, 0.08);
		}
	}

	&-cell {
		padding: @lapAndUpSpacingM;

		&--with-background {
			background-color: @gray200;
		}
	}

	&-row {
		border-bottom: 1px solid lightgray;
	}

	&--small {
		.table-cell {
			padding: @lapAndUpSpacingS @lapAndUpSpacingM;
		}
	}
}
