@import "../../../less/_variables/general.less";
@import "../../../less/_variables/color.less";
@import "../../../less/_variables/spacing.less";
@import "../../../less/_variables/typography.less";

@btnHeight: 40px;
@largeBtnHeight: 48px;

.floating-action-button {
	height: @largeBtnHeight;
	padding: 12px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: @palmSpacingS;
	background-color: @teal200;
	border: none;
	border-radius: @largeBtnHeight / 2;
	z-index: @fixToTopZIndex;
	font-size: @fontSizeXL;
	font-family: @fontFamilyStandard-theme-cosma;
	color: @gray1000;
	outline: none;
	cursor: pointer;
	box-shadow: 0 4px 10px 2px rgba(0, 0, 0, 0.08);
	appearance: none;

	&:hover {
		background-color: @teal400;
	}

	&-with-text {
		padding: 13px 23px;
		font-size: @fontSizeS;
		line-height: 22px;
		font-weight: 700;
		font-style: normal;

		& > svg {
			width: 16px;
			height: 16px;
		}

		& > span {
			font-size: 16px;
		}
	}

	&-secondary {
		background-color: @gray1000;
		color: @white;
		fill: @white;

		&:hover {
			background-color: @gray850;
		}
	}

	&-tertiary {
		background-color: @white;

		&:hover {
			background-color: @gray300;
		}
	}

	&[disabled] {
		background-color: @gray400;
		color: @gray700;
		cursor: inherit;
	}

	&-medium {
		height: @btnHeight;
		padding: 9px;
		border-radius: @btnHeight / 2;

		&.floating-action-button-with-text {
			padding: 9px 23px;
		}
	}
}
