// --- Positioning ---

// display modes
.inline {
	display: inline !important;
}
.inline-block {
	display: inline-block !important;
}
.block {
	display: block !important;
}

// alignment - horizontal
.align-center {
	text-align: center !important;
}
.align-left {
	text-align: left !important;
}
.align-right {
	text-align: right !important;
}

// alignment - vertical
.align-baseline {
	vertical-align: baseline !important;
}
.align-top {
	vertical-align: top !important;
}
.align-middle {
	vertical-align: middle !important;
}
.align-bottom {
	vertical-align: bottom !important;
}
.align-text-bottom {
	vertical-align: text-bottom !important;
}
.align-text-top {
	vertical-align: text-top !important;
}

// floats
.float-left {
	float: left !important;
}
.float-right {
	float: right !important;
}
.float-none {
	float: none !important;
}

// clear
.clear-both {
	clear: both !important;
}
.clear-left {
	clear: left !important;
}
.clear-right {
	clear: right !important;
}

// overflow
.overflow-hidden {
	overflow: hidden !important;
}

// visibility
.hide {
	display: none !important;
}
@media @mediaQueryPalmOnly {
	.@{prefixPalm}hide {
		display: none !important;
	}
}
@media @mediaQueryLapOnly {
	.@{prefixLap}hide {
		display: none !important;
	}
}
@media @mediaQueryDeskOnly {
	.@{prefixDesk}hide {
		display: none !important;
	}
}
@media @mediaQueryPrintOnly {
	.@{prefixPrint}hide {
		display: none !important;
	}
}

.invisible {
	visibility: hidden !important;
}
