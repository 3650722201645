/* --- OpenSans Font Face --- */
/*!
 *  Open Sans typeface by Steve Matteson (commissioned by Google)
 *  Apache License, Version 2.0 - http://www.apache.org/licenses/LICENSE-2.0.html
 */

 /* Removed all font definitions except for woff2 and woff because these are supported by all browsers that we support
 * Further information here:
 * https://css-tricks.com/snippets/css/using-font-face/
 * https://stackoverflow.com/questions/36105194/are-eot-ttf-and-svg-still-necessary-in-the-font-face-declaration/36110385#36110385
 * https://caniuse.com/#feat=woff
 * https://caniuse.com/#feat=woff2
 */


@makeitsans-path: "../font/vendor/make-it-sans";
@opensans-path: "../font/vendor/opensans/5";
@is24-icons-path: "../font/vendor/cosma-ui-icons";

@font-face {
	font-family: "s24-icons";
	src:
		url("@{is24-icons-path}/s24-icons.woff2") format("woff2"),
		url("@{is24-icons-path}/s24-icons.woff") format("woff"),
		url("@{is24-icons-path}/s24-icons.svg") format("svg");
}

@font-face {
	font-family: "Make It Sans IS24 Web";
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src:
		url("@{makeitsans-path}/MakeItSansIS24WEB-Regular.woff2") format("woff2"),
		url("@{makeitsans-path}/MakeItSansIS24WEB-Regular.woff")  format("woff");
}
@font-face {
	font-family: "Make It Sans IS24 Web";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src:
		url("@{makeitsans-path}/MakeItSansIS24WEB-Bold.woff2") format("woff2"),
		url("@{makeitsans-path}/MakeItSansIS24WEB-Bold.woff")  format("woff");
}

@font-face {
	font-family: "Open Sans";
	font-style:  normal;
	font-weight: 400;
	font-display: swap;
	src:
	     url("@{opensans-path}/opensans_latin_400.woff2")      format("woff2"),
	     url("@{opensans-path}/opensans_latin_400.woff")       format("woff");
}

@font-face {
	font-family: "Open Sans";
	font-style:  normal;
	font-weight: 600;
	font-display: swap;
	src:
	     url("@{opensans-path}/opensans_latin_600.woff2")      format("woff2"),
	     url("@{opensans-path}/opensans_latin_600.woff")       format("woff");
}

@media @mediaQueryLapAndUp {
	@font-face {
		font-family: "Open Sans";
		font-style:  normal;
		font-weight: 300;
		font-display: swap;
		src:
		     url("@{opensans-path}/opensans_latin_300.woff2")      format("woff2"),
		     url("@{opensans-path}/opensans_latin_300.woff")       format("woff");
	}
	
	@font-face {
		font-family: "Open Sans";
		font-style:  normal;
		font-weight: 700;
		font-display: swap;
		src:
		     url("@{opensans-path}/opensans_latin_700.woff2")      format("woff2"),
		     url("@{opensans-path}/opensans_latin_700.woff")       format("woff");
	}
}
