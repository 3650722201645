@import "../Input.less";
@import "../Labels.less";

// Hide spinner buttons in number input fields
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	// Appearance is not supported by Internet Explorer. This does not matter here, since this targets Webkit exclusively.
	/* doiuse-disable css-appearance */
	-webkit-appearance: none;
	/* doiuse-enable css-appearance */
}

input[type="number"] {
	// Appearance is not supported by Internet Explorer. This does not matter here, as Internet Explorer does not display the spinner buttons.
	// We mainly target Firefox here.
	/* doiuse-disable css-appearance */
	appearance: textfield;
	/* doiuse-enable css-appearance */
}
