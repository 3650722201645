@import "_mixins/general.less";

// --- Helpers ---

// inactive/disabled links
.link-text,
.button-icon-standalone,
a {
	&.disabled {
		&,
		&:link,
		&:visited,
		&:hover,
		&:focus,
		&:active {
			cursor: default;
			color: @linkDisabledColor;
			text-decoration: none;
		}
	}
}

// clear fix
.clearfix:after {
	.clearfix();
}

.clearfix-before:before {
	.clearfix();
}

// vertical centering

// Centers elements on the vertical axis of the parent element. Requires that the centered elements do not
// exceed the parent width minus the width of a space character (due to how the browser renders inline elements).

.vertical-center-container {
	height: 100%;
}

.vertical-center-container:after {
	content: "";
	height: 100%;
}

.vertical-center-container:after,
.vertical-center {
	display: inline-block;
	vertical-align: middle;
}

// horizontal center

.horizontal-center {
	margin: 0 auto;
	display: block; // to avoid a usage for inline-block elements
}

// cursor

.cursor-pointer {
	cursor: pointer;
}

// height
.height-full {
	height: 100%;
}

.core-hide {
	.theme-variation(display, none, initial);
}

.cosma-hide {
	.theme-variation(display, initial, none);
}

.core-hide--inline {
	.theme-variation(display, none, inline);
}

.cosma-hide--inline {
	.theme-variation(display, inline, none);
}

.core-hide--block {
	.theme-variation(display, none, block);
}

.cosma-hide--block {
	.theme-variation(display, block, none);
}

.core-hide--inline-block {
	.theme-variation(display, none, inline-block);
}

.cosma-hide--inline-block {
	.theme-variation(display, inline-block, none);
}

.core-hide--flex {
	.theme-variation(display, none, flex);
}

.cosma-hide--flex {
	.theme-variation(display, flex, none);
}

.core-hide--inline-flex {
	.theme-variation(display, none, inline-flex);
}

.cosma-hide--inline-flex {
	.theme-variation(display, inline-flex, none);
}
