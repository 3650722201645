// --- Absolute Positioning ---

.absolute-positioning(@prefix) {
	.@{prefix}absolute-reference {
		position: relative;
	}
	.@{prefix}absolute-content {
		position: absolute;
		// set positioning with inline styles
	}
}

.absolute-positioning(@empty);

@media @mediaQueryPalmOnly {
	.absolute-positioning(@prefixPalm);
}

@media @mediaQueryLapOnly {
	.absolute-positioning(@prefixLap);
}

@media @mediaQueryDeskOnly {
	.absolute-positioning(@prefixDesk);
}
