@import "../../../../less/_variables/color-palette.less";
@import "../../../../less/_variables/typography.less";
@import "../../../../less/_mixins/typography.less";
@import "../../../../less/_mixins/general.less";

.chip {
	@parent: chip;
	@icon-size: 16px;
	font-family: @fontFamilyStandard-theme-cosma;

	.color-scheme(color, @textColorStandard, @gray400);
	.color-scheme(background-color, @white, transparent);
	.color-scheme(border-color, @gray650, @gray700);

	.font-size-and-line-height-standard();
	line-height: 16px;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 6px;
	min-width: 60px;
	padding: 11px 15px;
	border-radius: 20px;
	border-width: 1px;
	border-style: solid;
	white-space: nowrap;
	user-select: none;
	cursor: pointer;

	&:active,
	&:hover {
		.color-scheme(background-color, @gray200, rgba(245, 245, 245, 0.12));
	}

	&:focus-visible {
		.color-scheme(border-color, transparent, transparent);

		box-shadow: 0px 0px 0px 3px @teal400;
	}

	&-small {
		padding-top: 7px;
		padding-bottom: 7px;
	}

	&-icon {
		width: @icon-size;
		height: @icon-size;

		svg {
			width: 100%;
			height: 100%;
		}

		[class^="is24-icon-"],
		[class^="s24-icons-"] {
			font-size: @icon-size;
		}
	}

	&-icon-circle {
		.color-scheme(border-color, @gray650, currentColor);

		display: block;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		border-width: 1px;
		border-style: solid;

		.@{parent}--disabled & {
			.color-scheme(border-color, @gray300, currentColor);
		}
	}

	&--active {
		.color-scheme(background-color, @teal25, rgba(0, 255, 208, 0.15));
		.color-scheme(border-color, @teal400, @teal400);

		&:active,
		&:hover {
			.color-scheme(background-color, #d3efe0, rgba(0, 255, 208, 0.25));
		}
	}

	&--disabled {
		.color-scheme(color, @color-light-copy, @gray700);
		.color-scheme(background-color, @white, transparent);
		.color-scheme(border-color, @gray400, @gray1000);

		cursor: default;

		&:hover {
			.color-scheme(background-color, @white, transparent);
		}

		&:focus-visible {
			.color-scheme(border-color, @gray400, @gray1000);

			box-shadow: none;
		}

		&.@{parent}--active {
			.color-scheme(color, @color-light-copy, @gray700);
			.color-scheme(background-color, @gray200, transparent);
			.color-scheme(border-color, @gray400, @gray1000);
		}
	}
}
