@secondaryLoaderSizeXSmall: 16px;
@secondaryLoaderSizeSmall: 24px;
@secondaryLoaderSizeMedium: 48px;
@secondaryLoaderSizeLarge: 96px;

.loaderSecondary {
	@initAngle1: -90deg;
	@initAngle2: -65deg;
	@initAngle3: -65deg;
	@offset: 185;
	@offset2: 177;
	@offset3: 177;
	@duration: 2s;

	max-width: 100%;
	max-height: 100%;

	&.loader-xsmall {
		width: @secondaryLoaderSizeXSmall;
		height: @secondaryLoaderSizeXSmall;
	}
	&.loader-small {
		width: @secondaryLoaderSizeSmall;
		height: @secondaryLoaderSizeSmall;
	}
	&.loader-medium {
		width: @secondaryLoaderSizeMedium;
		height: @secondaryLoaderSizeMedium;
	}
	&.loader-large {
		width: @secondaryLoaderSizeLarge;
		height: @secondaryLoaderSizeLarge;
	}

	&.colorSet-aquaGreen {
		.path1 {
			stroke: #00ffd0;
		}
		.path2 {
			stroke: transparent;
		}
		.path3 {
			stroke: #00ffd0;
		}
	}

	&.colorSet-aquaBlue {
		.path1 {
			stroke: #00ffd0;
		}
		.path2 {
			stroke: transparent;
		}
		.path3 {
			stroke: #007bff;
		}
	}

	&.colorSet-solidBlue {
		.path1 {
			stroke: #007bff;
		}
		.path2 {
			stroke: transparent;
		}
		.path3 {
			stroke: #007bff;
		}
	}

	&.colorSet-pureWhite {
		.path1 {
			stroke: #ffffff;
		}
		.path2 {
			stroke: transparent;
		}
		.path3 {
			stroke: #ffffff;
		}
	}

	&.colorSet-darkGray {
		.path1 {
			stroke: #333333;
		}
		.path2 {
			stroke: transparent;
		}
		.path3 {
			stroke: #333333;
		}
	}

	&.colorSet-silverGray {
		.path1 {
			stroke: #c4c4c4;
		}
		.path2 {
			stroke: transparent;
		}
		.path3 {
			stroke: #c4c4c4;
		}
	}

	svg {
		width: 100%;
		height: 100%;
		circle {
			transition: stroke 0.5s ease-in-out;
			stroke-width: 6;
			stroke-linecap: butt;
			fill: none;
		}
	}

	.path {
		stroke-dasharray: @offset;
		transform-origin: center;
	}
	.path1 {
		stroke-dashoffset: 0;
		transform: rotate(@initAngle1);
		animation: dash @duration ease-in-out infinite;
	}
	.path2 {
		stroke-dashoffset: @offset2;
		transform: rotate(@initAngle2);
		animation: dash2 @duration ease-in-out infinite 1.1s;
	}
	.path3 {
		stroke-dashoffset: @offset3;
		transform: rotate(@initAngle3);
		animation: dash3 @duration ease-in-out infinite 1.3s;
	}

	@keyframes dash {
		0% {
			stroke-dashoffset: @offset;
		}
		50% {
			stroke-dashoffset: 0;
			transform: rotate(@initAngle1);
		}
		100% {
			stroke-dashoffset: @offset;
			transform: rotate(@initAngle1 + 360);
		}
	}
	@keyframes dash2 {
		0% {
			stroke-dashoffset: @offset2;
		}
		25% {
			stroke-dashoffset: 225;
		}
		50%,
		100% {
			stroke-dashoffset: @offset2;
			transform: rotate(@initAngle2 + 360);
		}
	}
	@keyframes dash3 {
		0% {
			stroke-dashoffset: @offset3;
		}
		25% {
			stroke-dashoffset: 205;
		}
		50%,
		100% {
			stroke-dashoffset: @offset3;
			transform: rotate(@initAngle3 + 360);
		}
	}
}
