/* --- Fixed-to-top Elements --- */

// Use .fix-to-top to make an element stick to the top of the page as soon as the browser window scrolls beyond its top edge.
// The surrounding .fix-to-top-wrapper acts as an anchor to keep track of the boundary where the element needs to be fixed.

/* fixed element */
.fix-to-top-wrapper {
	position: relative;
}
.fix-to-top--fixed {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: @fixToTopZIndex;
}
.fix-to-top--fixed {
	.transform(translateZ(0px)); // fix for webkit bug where sometimes (on tablets in portrait mode) fixed elements are not correctly repainted after scrolling, which will cause erratic jumping
	
	// Beware: This will break layout if the fixed element contains elements taller than itself.
	// Be sure to apply the same transform fix to any descendants that are taller that the fixed element.
	// (Won't do this here via a wildcard selector to minimize applications in elements with large sub trees, such as the top navigation.)
}