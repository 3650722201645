@import "../../../less/_variables/typography.less";
@import "../../../less/_variables/color-palette.less";

.agents-card {
	position: relative;
	width: 263px;
	height: 477px;
	border: 1px solid @gray400;
	border-radius: 16px; // Integrate with CSS variables in Cosma v7
	overflow: hidden;
	font-family: @fontFamilyStandard-theme-cosma;

	&-partner-badge {
		position: absolute;
		top: 232px;
		left: 8px;
	}

	&-image {
		width: 100%;
		height: 263px;
		display: block;
		object-fit: cover;
	}

	&-body {
		display: grid;
		grid-template-areas:
			"title"
			"badge"
			"description"
			"rating";
		grid-row-gap: 4px;
		padding: 16px;
	}

	&-title {
		margin-bottom: 4px;
		height: 56px; // 2x line-height
		color: @gray1000;
		font-size: 18px;
		line-height: 28px;
		font-weight: 700;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		grid-area: title;
	}

	&-badge {
		margin-bottom: 4px;
		height: 40px;
		grid-area: badge;

		img {
			height: 100%;
			width: auto;
		}
	}

	&-description {
		grid-area: description;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}

	&-rating {
		grid-area: rating;
		display: flex;
		align-items: center;
		height: 16px;

		div:first-child {
			height: 16px;
			margin-right: 4px;
		}

		&-summary {
			font-size: 14px;
			line-height: 22px;
			font-weight: 400;
			color: @gray850;
		}

		&-current {
			font-weight: 700;
			color: @gray1000;
		}
	}

	&-stars {
		display: flex;
	}

	&-additional-info {
		font-size: 12px;
		line-height: 18px;
		font-weight: 400;
		color: @gray850;
	}
}
