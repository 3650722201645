//Slide animation
.slide-enter {
	transform: translateY(-130%);
}
.slide-enter-active {
	transform: translateY(0);
	transition: transform 300ms ease-in;
}

.slide-exit {
	transform: translate3d(0, 0, 0);
}
.slide-exit-active {
	transform: translate3d(0, -130%, 0);
	transition: transform 300ms ease-out;
}

//Fade animation

.fade-enter {
	opacity: 0;
}
.fade-enter-active {
	opacity: 1;
	transition: opacity 300ms ease-in;
}

.fade-exit {
	opacity: 1;
}
.fade-exit-active {
	opacity: 0;
	transition: opacity 300ms ease-out;
}
