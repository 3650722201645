@import "./_variables/color-palette.less";
@import "./_mixins/general.less";

// --- Page Frame ---

html {
	font-size: @rootFontSize; // root font size for rem-based styles
}

body {
	background-color: @bodyBackgroundColor;
	color: @textColorStandard;

	//.font-size-rem(@palmFontSizeStandard); // turned into em value until Chrome bug regarding rem has been fixed (see: https://code.google.com/p/chromium/issues/detail?id=319623)
	@fontSizeStandardEm: unit(@fontSizeStandard);
	font-size: ~"@{fontSizeStandardEm}em";
	line-height: @lineHeightStandard;

	&,
	[data-theme="core"],
	&[data-theme="core"] {
		font-family: @fontFamilyStandard-theme-core;
	}

	[data-theme="cosma"],
	&[data-theme="cosma"] {
		font-family: @fontFamilyStandard-theme-cosma;
	}
}

// DEPRECATED (will be removed in next major version)
//
// Purpose of this class was to simulate light/dark surrounding of a component.
// It meant to be used only internally by storybook. Unfortunately it was present
// inside some code snippets in stories and this class started to be present in
// various apps in production. Additonally this class styles color property which
// might create false impression that dark mode is styled properly.
//
// Please use class .color-scheme-test going forward but only inside storybook
// and only as a decorator.
.with-dark-mode {
	.color-scheme(background-color, @bodyBackgroundColor, @bodyBackgroundColorDark);
	.color-scheme(color, @textColorStandard, @textColorStandardDark);
}

.page-wrapper {
	margin-left: auto; // centered by default
	margin-right: auto;
	max-width: @pageWidthMax;
	background-color: @pageBackgroundColor;
}

.page-wrapper--full-width {
	margin-left: 0;
	margin-right: 0;
	max-width: none;
}

@media @mediaQueryDeskAndUp {
	.page-wrapper--full-width {
		padding-right: 0;
		padding-left: 0;
		border: none;
	}
}

.content-wrapper {
	margin: 0 auto;
	padding: 0 @contentColumnHorizontalPaddingPalm;
	max-width: @contentColumnMaxWidth;

	@media @mediaQueryLapAndUp {
		padding: 0 @contentColumnHorizontalPaddingLapAndUp;
	}

	&:before,
	&:after {
		.clearfix();
	}
}

// negate padding to get full width elements
.content-wrapper--negate-padding {
	margin-left: -@contentColumnHorizontalPaddingPalm;
	margin-right: -@contentColumnHorizontalPaddingPalm;

	@media @mediaQueryLapAndUp {
		margin-left: -@contentColumnHorizontalPaddingLapAndUp;
		margin-right: -@contentColumnHorizontalPaddingLapAndUp;
	}
}

.content-wrapper--compressed {
	max-width: @pageWidthMin; // compresses content wrapper to minimum content width regardless of space available
}

.content-wrapper--background {
	background-color: @pageBackgroundColor; // adds standard background to content column - optional because content columns can be included in other modules
}
