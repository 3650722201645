// --- Accessibility Tools for Touch Interfaces ---

// link lists with increased clickable area

// mixin to reduce redundancy
.touch-area(@prefix) {
	ul.@{prefix}touch-area a,
	a.@{prefix}touch-area {
		display: block;
		padding-top: 0.5em;
		padding-bottom: 0.5em;
		width: 100%; // in case links are made inline-block by conflicting classes
	}
	// reset possible top and bottom margins on list items and nested lists to prevent gaps between two touch-area links (e.g. from child margin classes)
	ul.@{prefix}touch-area li,
	ul.@{prefix}touch-area ul {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}
}

// all devices
.touch-area(@empty);

// palm devices
@media @mediaQueryPalmOnly {
	.touch-area(@prefixPalm);
}

// lap-sized devices
@media @mediaQueryLapOnly {
	.touch-area(@prefixLap);
}

// desk-top devices
@media @mediaQueryDeskOnly {
	.touch-area(@prefixDesk);
}
