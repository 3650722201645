// --- Forms ---

fieldset + fieldset > .grid-flex.gutter-form,
.grid-flex.gutter-form + .grid-flex.gutter-form {
	margin-top: 0;
}

// prevent horizontal resizing of textarea elements to avoid expanding the containing fieldset
.form .textarea {
	// Resize is not supported by Internet Explorer and Edge. This is okay since on these, textareas are not resizable, anyway.
	/* doiuse-disable css-resize */
	resize: vertical;
	/* doiuse-enable css-resize */
}

input[type='submit'] {
	cursor: pointer;
}
