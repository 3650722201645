@import "../../../../less/_mixins/general.less";
@import "../../../../less/_variables/color.less";
@import "../../../../less/_variables/color-palette.less";
@import "../../../../less/_variables/typography.less";

.pagination {
	& > li {
		display: inline;
	}
}

.pagination-button, .pagination-button-next, .pagination-button-prev {
	display: inline-block;
	outline: none;
	.remove-button-style();

	& span {
		vertical-align: -5%
	}
}

.pagination-button {
	color: @blue600;
	padding: 8px;
	margin: 0 2px;
	font-family: @fontFamilyStandard-theme-cosma;

	&:hover {
		text-decoration: underline;
	}
}

.pagination-button-active, .pagination-button-ellipsis {
	color: @color-light-copy;
}

.pagination-button-next, .pagination-button-prev {
	background-color: @gray1000;
	border-radius: 8px;
	padding: 8px 9px;
	color: @gray100;

	&:disabled, .pagination-button-disabled {
		background-color: @gray400;
		color: @gray800;
		cursor: default;
	}
}

.pagination-button-prev {
	margin-right: 11px;
}

.pagination-button-next {
	margin-left: 11px;
}
