// --- Widths ---

// template for width definition blocks
// -- usage:
// -- call with state prefix (@prefixPalm, @prefixLap, @prefixDesk) to create state-specific classes
// -- call with @empty to create generic classes
// -- append "!important" after mixin call to make all classes important
.widths(@prefix) {
	// auto
	.@{prefix}automatic-width {
		width: auto;
	}
	
	// whole
	.@{prefix}one-whole {
		width: 100%;
	}

	// The plural s in these is confusing for some developers, so we're making it optional.
	
	// halves
	.@{prefix}one-half,
	.@{prefix}two-fourth,
	.@{prefix}two-fourths,
	.@{prefix}four-eighth,
	.@{prefix}four-eighths,
	.@{prefix}three-sixth,
	.@{prefix}three-sixths,
	.@{prefix}five-tenth,
	.@{prefix}five-tenths,
	.@{prefix}six-twelfth,
	.@{prefix}six-twelfths {
		width: 50%;
	}
	
	// thirds
	.@{prefix}one-third,
	.@{prefix}two-sixth,
	.@{prefix}two-sixths,
	.@{prefix}four-twelfth,
	.@{prefix}four-twelfths {
		width: 33.333%;
	}

	.@{prefix}two-third,
	.@{prefix}two-thirds,
	.@{prefix}four-sixth,
	.@{prefix}four-sixths,
	.@{prefix}eight-twelfth,
	.@{prefix}eight-twelfths {
		width: 66.666%;
	}
	
	// fourths
	.@{prefix}one-fourth,
	.@{prefix}two-eighth,
	.@{prefix}two-eighths,
	.@{prefix}three-twelfth,
	.@{prefix}three-twelfths {
		width: 25%;
	}

	.@{prefix}three-fourth,
	.@{prefix}three-fourths,
	.@{prefix}six-eighth,
	.@{prefix}six-eighths,
	.@{prefix}nine-twelfth,
	.@{prefix}nine-twelfths {
		width: 75%;
	}
	
	// fifths
	.@{prefix}one-fifth,
	.@{prefix}two-tenth,
	.@{prefix}two-tenths {
		width: 20%;
	}

	.@{prefix}two-fifth,
	.@{prefix}two-fifths,
	.@{prefix}four-tenth,
	.@{prefix}four-tenths {
		width: 40%;
	}

	.@{prefix}three-fifth,
	.@{prefix}three-fifths,
	.@{prefix}six-tenth,
	.@{prefix}six-tenths {
		width: 60%;
	}

	.@{prefix}four-fifth,
	.@{prefix}four-fifths,
	.@{prefix}eight-tenth,
	.@{prefix}eight-tenths {
		width: 80%;
	}
	
	// sixths
	.@{prefix}one-sixth,
	.@{prefix}two-twelfth,
	.@{prefix}two-twelfths {
		width: 16.666%;
	}

	.@{prefix}five-sixth,
	.@{prefix}five-sixths,
	.@{prefix}ten-twelfth,
	.@{prefix}ten-twelfths {
		width: 83.333%;
	}
	
	// eighths
	.@{prefix}one-eighth {
		width: 12.5%;
	}

	.@{prefix}three-eighth,
	.@{prefix}three-eighths {
		width: 37.5%;
	}

	.@{prefix}five-eighth,
	.@{prefix}five-eighths {
		width: 62.5%;
	}

	.@{prefix}seven-eighth,
	.@{prefix}seven-eighths {
		width: 87.5%;
	}
	
	// tenths
	.@{prefix}one-tenth {
		width: 10%;
	}

	.@{prefix}three-tenth,
	.@{prefix}three-tenths {
		width: 30%;
	}

	.@{prefix}seven-tenth,
	.@{prefix}seven-tenths {
		width: 70%;
	}

	.@{prefix}nine-tenth,
	.@{prefix}nine-tenths {
		width: 90%;
	}
	
	// twelfths
	.@{prefix}one-twelfth {
		width: 8.333%;
	}

	.@{prefix}five-twelfth,
	.@{prefix}five-twelfths {
		width: 41.666%;
	}

	.@{prefix}seven-twelfth,
	.@{prefix}seven-twelfths {
		width: 58.333%;
	}

	.@{prefix}ten-twelfth,
	.@{prefix}ten-twelfths {
		width: 83.333%;
	}

	.@{prefix}eleven-twelfth,
	.@{prefix}eleven-twelfths {
		width: 91.666%;
	}
}

.widths(@empty) !important;
@media @mediaQueryPalmOnly {
	.widths(@prefixPalm) !important;
}
@media @mediaQueryLapOnly {
	.widths(@prefixLap) !important;
}
@media @mediaQueryDeskOnly {
	.widths(@prefixDesk) !important;
}
