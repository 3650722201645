// --- Mixins ---

// box-sizing
.box-sizing(@value) {
	-webkit-box-sizing: @arguments;
	-moz-box-sizing: @arguments;
	-ms-box-sizing: @arguments;
	-o-box-sizing: @arguments;
	box-sizing: @arguments;
}

// user select
.user-select(@value) {
	-webkit-user-select: @value;
	-khtml-user-select: @value;
	-moz-user-select: @value;
	-ms-user-select: @value;
	user-select: @value;
}

// Transition
.transition(@value) {
	-webkit-transition: @value;
	-moz-transition: @value;
	-o-transition: @value;
	transition: @value;
}

// Transform
.transform(@value) {
	-webkit-transform: @value;
	-moz-transform: @value;
	-ms-transform: @value;
	-o-transform: @value;
	transform: @value;
}

// animation
.animation(@value) {
	-webkit-animation: @value;
	-moz-animation: @value;
	-o-animation: @value;
	animation: @value;
}

.remove-button-style() {
	border: none;
	cursor: pointer;
	background-color: transparent; // Remove button style for Safari
}

// clear fix for use on :before or :after pseudo elements
.clearfix() {
	display: table;
	clear: both;
	content: "";
}

// takes rem values and generates pixel-based fallback values for oldIE
// based on base size for desk-top devices
.font-size-rem(@value) {
	@unitlessValue: unit(@value);
	@unitlessRootFontSize: unit(@rootFontSize);
	font-size: round(16px * (@unitlessRootFontSize / 100) * @unitlessValue); // can't rely on LESS's percent value computation
	font-size: ~"@{unitlessValue}rem";
}

// This is our way of handling multiple themes. It allows users to provide a theme value and have underlying HTML elements styled accordingly. It is documented under "Getting Started/Rebranding" in Cosmabook.
// In practice, CSS variables would work better but Internet Explorer is not compatible with them. There are polyfills available but they are not very performant, plus users would have to include an additional JavaScript file.

// TO BE DEPRECATED:
// Please use theme-protection or color-scheme mixins below
.theme-variation(@parameter, @coreVariableName, @cosmaVariableName) {
	&,
	[data-theme="core"] & {
		@{parameter}: @coreVariableName;
	}

	[data-theme="cosma"] & {
		@{parameter}: @cosmaVariableName;
	}
}

// Special version of theme variation for instances where setting a theme will destroy the specified look.
.theme-protection(@parameter, @variableName) {
	&,
	[data-theme="core"] &,
	[data-theme="cosma"] & {
		@{parameter}: @variableName;
	}
}

.color-scheme(@parameter, @light, @dark) {
	&,
	[data-color-scheme="light"] & {
		@{parameter}: @light;
	}

	[data-color-scheme="dark"] & {
		@{parameter}: @dark;
	}
}

.color-scheme-light(@parameter, @light) {
	&,
	[data-color-scheme="light"] & {
		@{parameter}: @light;
	}
}

.color-scheme-dark(@parameter, @dark) {
	[data-color-scheme="dark"] & {
		@{parameter}: @dark;
	}
}

// - Helpers

// triangle icon
.triangle(@_, @color, @size) {
	border-width: @size;
	border-style: solid;
	width: 0;
	height: 0;
}

.triangle(up, @color, @size) {
	border-color: transparent transparent @color transparent;
}

.triangle(right, @color, @size) {
	border-color: transparent transparent transparent @color;
}

.triangle(down, @color, @size) {
	border-color: @color transparent transparent transparent;
}

.triangle(left, @color, @size) {
	border-color: transparent @color transparent transparent;
}
