@import "../../../less/_variables/spacing.less";
@import "../../../less/_variables/media-queries.less";

.plus-value-proposition {
	display: flex;
	gap: @palmSpacingM;

	@media @mediaQueryLapAndUp {
		gap: @lapAndUpSpacingM;
	}

	&-icon-wrapper {
		position: relative;
		flex-shrink: 0;
		width: 40px;
		height: 40px;
	}

	&-primary-icon {
		@icon-size: 3rem;

		position: absolute;
		bottom: 0;
		right: 0;
		color: #f5f200;

		svg& {
			width: @icon-size;
			height: @icon-size;
		}

		[class^="is24-icon-"]&,
		[class^="s24-icons-"]& {
			font-size: @icon-size;
		}
	}

	&-secondary-icon {
		@icon-size: 1.8rem;

		position: absolute;
		color: #ff9015;

		svg& {
			width: @icon-size;
			height: @icon-size;
		}

		[class^="is24-icon-"]&,
		[class^="s24-icons-"]& {
			font-size: @icon-size;
		}
	}

	&-content {
		display: flex;
		flex-grow: 1;
		flex-direction: column;
		justify-content: center;
	}
}
