@import "../../../less/_variables/typography.less";
@import "../../../less/_variables/color-palette.less";

.real-estate-card {
	width: 263px;
	background-color: @gray100;
	border: 1px solid @gray400;
	border-radius: 16px; // Integrate with CSS variables in Cosma v7
	overflow: hidden;
	font-family: @fontFamilyStandard-theme-cosma;

	&-image {
		width: 100%;
		height: 197px;
		display: block;
		object-fit: cover;
	}

	&-body {
		padding: 16px;
	}

	&-container {
		display: flex;
		gap: 8px;
	}

	&-content {
		display: flex;
		flex-direction: column;
		flex: 1;
		gap: 8px;
		overflow: hidden;
	}

	&-title {
		height: 44px; // 2x line-height
		color: @gray1000;
		font-size: 14px;
		line-height: 22px;
		font-weight: 700;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}

	&-criteria {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		gap: 16px;

		& > * {
			margin: 0;
			white-space: nowrap;
		}

		& > *:last-child {
			text-wrap: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&-button {
		color: @gray1000;
		padding: 0;
		height: 24px;

		&:hover,
		&:focus {
			color: @gray1000;
		}
	}

	&-address {
		margin-top: 8px;
		display: flex;
		align-items: center;
		color: @gray850;

		&-content {
			margin-left: 4px;
			flex: 1;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
	}

	&-pin {
		width: 16px;
		height: 16px;
	}
}
