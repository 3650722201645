@import "../../../../../less/_variables/media-queries.less";
@import "../../../../../less/_variables/color.less";

.checkbox-frame {
	background-color: @gray200;
	border-radius: 4px;
	padding: @palmSpacingStandard;

	@media @mediaQueryLapAndUp {
		padding: @lapAndUpSpacingStandard;
	}


	&:has(.input-checkbox:focus),
	&[data-focused] {
		background-color: @gray300;
	}

	&:has(.checkbox-container:hover),
	&[data-hovered] {
		background-color: @gray300;
	}
}
