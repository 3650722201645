@import "../less/_mixins/general.less";

// --- Link Icons ---

.with-icon {
	color: @linkColor-with-icon-theme-cosma;
	.flex-display(flex);
	.align-items(center);

	a,
	a& {
		color: @linkColor-with-icon-theme-cosma;
		text-decoration: none;

		&:hover {
			color: @linkColor-with-icon-theme-cosma;
			text-decoration: underline;
		}
	}
	[class*="is24-icon"] + a, a + [class*="is24-icon"],
	[class*=" s24-icons-"] + a, a + [class*=" s24-icons-"] + a,
	[class^="s24-icons-"] + a, a + [class^="s24-icons-"],
	svg + a, a + svg

	{
		padding-left: 4px;
	}
}
.with-icon.link-charcoal {
	color: @gray1000;

	a,
	a& {
		color: @gray1000;

		&:hover {
			color: @gray1000;
		}
	}
}


.default-icon-styles() {
	content: "";
	display: inline-block;
	font-family: "IS24Icons";
	font-size: 0.8em;
}


// Global icon styles

.icon-arrow,
.icon-arrow-back,
.icon-arrow-forward {
	ul& > li a,
	a&,
	button&,
	span& {
		display: inline-block;
	}
}


// Individual styles for left- and right-aligned icons

.icon-arrow,
.icon-arrow-back {
	ul& > li a,
	a&,
	button&,
	span& {
		position: relative; // reference point for absolutely positioned icons
		padding-left: .8em; // keeps text from wrapping around icon on line break

		&::before {
			position: absolute;
			left: -0.2em;
			margin-top: 0.2em;
			content: "";
			display: inline-block;
			.default-icon-styles();
		}
	}
}

.icon-arrow-forward {
	ul& > li a,
	a&,
	button&,
	span& {
		&::after {
			.default-icon-styles();
			margin-left: 0.25em;
		}
	}
}


// Arrow icon before text

.icon-arrow {
	ul& > li a,
	a&,
	button&,
	span& {
		&::before {
			content: "\e916";
		}
	}
}


// Arrow icon before text - pointing backward

.icon-arrow-back {
	ul& > li a,
	a&,
	button&,
	span& {
		&::before {
			content: "\e915";
		}
	}
}


// Arrow icon after text - pointing forward

.icon-arrow-forward {
	ul& > li a,
	a&,
	button&,
	span& {
		&::after {
			content: "\e916";
		}
	}
}

// Icon colors on disabled links
.disabled {
	ul > li a&,
	a&,
	button&,
	span& {
		&::before,
		&::after {
			color: @linkIconStandardDisabledColor;
		}
	}
}
// Needs to be in sync with https://github.com/Scout24/cosma-ui-icons/blob/b136c94a23e9864713a8cf13d315dd7ee7236712/scripts/generate.js#L46-L51
.s24-svg-inline svg {
	display: inline-block;
	width: 1em;
	height: 1em;
	position: relative;
	top: 0.125em;
	stroke-width: 0;
}
