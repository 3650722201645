// --- Device-specific Resets ---

// Put stuff here that is intended to fix non-standard User Agent behavior.


body {
	// -webkit-text-size-adjust only targets iOS devices. This is intended.
	/* doiuse-disable text-size-adjust */
	-webkit-text-size-adjust: none; // Prevents font size increase when switching to portrait mode on iOS devices
	/* doiuse-enable text-size-adjust */
}