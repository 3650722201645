@import "../_variables/general.less";
@import "../_variables/typography.less";
@import "../_variables/media-queries.less";

// Deprecated START
// Please use .text-[identifier] mixins to provide typographic styles

.font-sizes(@prefix) {
	.@{prefix}font-xs {
		font-size: @fontSizeXS !important;
	}

	.@{prefix}font-s {
		font-size: @fontSizeS !important;
	}

	.@{prefix}font-standard {
		font-size: @fontSizeStandard !important;
	}

	.@{prefix}font-m {
		font-size: @fontSizeM !important;
	}

	.@{prefix}font-l {
		font-size: @fontSizeL !important;
	}

	.@{prefix}font-xl {
		font-size: @fontSizeXL !important;
	}

	.@{prefix}font-xxl {
		font-size: @fontSizeXXL !important;
	}

	.@{prefix}font-xxxl {
		font-size: @fontSizeXXXL !important;
	}
}

.font-sizes(@empty);
@media @mediaQueryPalmOnly {
	.font-sizes(@prefixPalm);
}
@media @mediaQueryLapOnly {
	.font-sizes(@prefixLap);
}
@media @mediaQueryDeskOnly {
	.font-sizes(@prefixDesk);
}

// Deprecated END

h1 {
	.text(heading-1);
}

h2 {
	.text(heading-2);
}

h3 {
	.text(heading-3);
}

h4 {
	.text(heading-4);
}

h5,
h6 {
	.text(heading-5);
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-bottom: @headlineBottomMarginStandard;
}
