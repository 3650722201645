// --- Spacing ---

// template for spacing blocks
// -- usage:
// -- call with arguments: property to set (margin/padding), standard spacing, spacings xs through xl
// -- append "!important" after mixin call to make all classes important
.spacing(@prefix; @property; @spacingStandard; @spacingXS; @spacingS; @spacingM; @spacingL; @spacingXL; @spacingXXL) {
	// resets
	.@{prefix}@{property}-none,
	.@{prefix}@{property}-top-none,
	.@{prefix}@{property}-vertical-none {
		@{property}-top:    0;
	}

	.@{prefix}@{property}-none,
	.@{prefix}@{property}-right-none,
	.@{prefix}@{property}-horizontal-none {
		@{property}-right:  0;
	}

	.@{prefix}@{property}-none,
	.@{prefix}@{property}-bottom-none,
	.@{prefix}@{property}-vertical-none {
		@{property}-bottom: 0;
	}

	.@{prefix}@{property}-none,
	.@{prefix}@{property}-left-none,
	.@{prefix}@{property}-horizontal-none {
		@{property}-left:   0;
	}

	/* stylelint-disable */
	// all sides
	.@{prefix}@{property}     { @{property}: @spacingStandard; }
	.@{prefix}@{property}-xs  { @{property}: @spacingXS; }
	.@{prefix}@{property}-s   { @{property}: @spacingS; }
	.@{prefix}@{property}-m   { @{property}: @spacingM; }
	.@{prefix}@{property}-l   { @{property}: @spacingL; }
	.@{prefix}@{property}-xl  { @{property}: @spacingXL; }
	.@{prefix}@{property}-xxl { @{property}: @spacingXXL; }

	// specific sides
	.@{prefix}@{property}-top,        .@{prefix}@{property}-vertical       { @{property}-top:    @spacingStandard; }
	.@{prefix}@{property}-right,      .@{prefix}@{property}-horizontal     { @{property}-right:  @spacingStandard; }
	.@{prefix}@{property}-bottom,     .@{prefix}@{property}-vertical       { @{property}-bottom: @spacingStandard; }
	.@{prefix}@{property}-left,       .@{prefix}@{property}-horizontal     { @{property}-left:   @spacingStandard; }
	.@{prefix}@{property}-top-xs,     .@{prefix}@{property}-vertical-xs    { @{property}-top:    @spacingXS; }
	.@{prefix}@{property}-right-xs,   .@{prefix}@{property}-horizontal-xs  { @{property}-right:  @spacingXS; }
	.@{prefix}@{property}-bottom-xs,  .@{prefix}@{property}-vertical-xs    { @{property}-bottom: @spacingXS; }
	.@{prefix}@{property}-left-xs,    .@{prefix}@{property}-horizontal-xs  { @{property}-left:   @spacingXS; }
	.@{prefix}@{property}-top-s,      .@{prefix}@{property}-vertical-s     { @{property}-top:    @spacingS; }
	.@{prefix}@{property}-right-s,    .@{prefix}@{property}-horizontal-s   { @{property}-right:  @spacingS; }
	.@{prefix}@{property}-bottom-s,   .@{prefix}@{property}-vertical-s     { @{property}-bottom: @spacingS; }
	.@{prefix}@{property}-left-s,     .@{prefix}@{property}-horizontal-s   { @{property}-left:   @spacingS; }
	.@{prefix}@{property}-top-m,      .@{prefix}@{property}-vertical-m     { @{property}-top:    @spacingM; }
	.@{prefix}@{property}-right-m,    .@{prefix}@{property}-horizontal-m   { @{property}-right:  @spacingM; }
	.@{prefix}@{property}-bottom-m,   .@{prefix}@{property}-vertical-m     { @{property}-bottom: @spacingM; }
	.@{prefix}@{property}-left-m,     .@{prefix}@{property}-horizontal-m   { @{property}-left:   @spacingM; }
	.@{prefix}@{property}-top-l,      .@{prefix}@{property}-vertical-l     { @{property}-top:    @spacingL; }
	.@{prefix}@{property}-right-l,    .@{prefix}@{property}-horizontal-l   { @{property}-right:  @spacingL; }
	.@{prefix}@{property}-bottom-l,   .@{prefix}@{property}-vertical-l     { @{property}-bottom: @spacingL; }
	.@{prefix}@{property}-left-l,     .@{prefix}@{property}-horizontal-l   { @{property}-left:   @spacingL; }
	.@{prefix}@{property}-top-xl,     .@{prefix}@{property}-vertical-xl    { @{property}-top:    @spacingXL; }
	.@{prefix}@{property}-right-xl,   .@{prefix}@{property}-horizontal-xl  { @{property}-right:  @spacingXL; }
	.@{prefix}@{property}-bottom-xl,  .@{prefix}@{property}-vertical-xl    { @{property}-bottom: @spacingXL; }
	.@{prefix}@{property}-left-xl,    .@{prefix}@{property}-horizontal-xl  { @{property}-left:   @spacingXL; }
	.@{prefix}@{property}-top-xxl,    .@{prefix}@{property}-vertical-xxl   { @{property}-top:    @spacingXXL; }
	.@{prefix}@{property}-right-xxl,  .@{prefix}@{property}-horizontal-xxl { @{property}-right:  @spacingXXL; }
	.@{prefix}@{property}-bottom-xxl, .@{prefix}@{property}-vertical-xxl   { @{property}-bottom: @spacingXXL; }
	.@{prefix}@{property}-left-xxl,   .@{prefix}@{property}-horizontal-xxl { @{property}-left:   @spacingXXL; }
	/* stylelint-enable */
}

// template for gap definition blocks
// -- usage:
// -- call with arguments: standard spacing, spacings xs through xl
// -- append "!important" after mixin call to make all classes important
.gap(@prefix; @spacingStandard; @spacingXS; @spacingS; @spacingM; @spacingL; @spacingXL; @spacingXXL) {
	.@{prefix}gap     { gap: @spacingStandard; }
	.@{prefix}gap-xs  { gap: @spacingXS; }
	.@{prefix}gap-s   { gap: @spacingS; }
	.@{prefix}gap-m   { gap: @spacingM; }
	.@{prefix}gap-l   { gap: @spacingL; }
	.@{prefix}gap-xl  { gap: @spacingXL; }
	.@{prefix}gap-xxl { gap: @spacingXXL; }
}

// Margin

.spacing(@empty; margin; @palmSpacingStandard; @palmSpacingXS; @palmSpacingS; @palmSpacingM; @palmSpacingL; @palmSpacingXL; @palmSpacingXXL) !important;

@media @mediaQueryLapAndUp {
	.spacing(@empty; margin; @lapAndUpSpacingStandard; @lapAndUpSpacingXS; @lapAndUpSpacingS; @lapAndUpSpacingM; @lapAndUpSpacingL; @lapAndUpSpacingXL; @lapAndUpSpacingXXL) !important;
}

@media @mediaQueryPalmOnly {
	.spacing(@prefixPalm; margin; @palmSpacingStandard; @palmSpacingXS; @palmSpacingS; @palmSpacingM; @palmSpacingL; @palmSpacingXL; @palmSpacingXXL) !important;
}

@media @mediaQueryLapOnly {
	.spacing(@prefixLap; margin; @lapAndUpSpacingStandard; @lapAndUpSpacingXS; @lapAndUpSpacingS; @lapAndUpSpacingM; @lapAndUpSpacingL; @lapAndUpSpacingXL; @lapAndUpSpacingXXL) !important;
}

@media @mediaQueryDeskOnly {
	.spacing(@prefixDesk; margin; @lapAndUpSpacingStandard; @lapAndUpSpacingXS; @lapAndUpSpacingS; @lapAndUpSpacingM; @lapAndUpSpacingL; @lapAndUpSpacingXL; @lapAndUpSpacingXXL) !important;
}

// Padding

.spacing(@empty; padding; @palmSpacingStandard; @palmSpacingXS; @palmSpacingS; @palmSpacingM; @palmSpacingL; @palmSpacingXL; @palmSpacingXXL) !important;

@media @mediaQueryLapAndUp {
	.spacing(@empty; padding; @lapAndUpSpacingStandard; @lapAndUpSpacingXS; @lapAndUpSpacingS; @lapAndUpSpacingM; @lapAndUpSpacingL; @lapAndUpSpacingXL; @lapAndUpSpacingXXL) !important;
}

@media @mediaQueryPalmOnly {
	.spacing(@prefixPalm; padding; @palmSpacingStandard; @palmSpacingXS; @palmSpacingS; @palmSpacingM; @palmSpacingL; @palmSpacingXL; @palmSpacingXXL) !important;
}

@media @mediaQueryLapOnly {
	.spacing(@prefixLap; padding; @lapAndUpSpacingStandard; @lapAndUpSpacingXS; @lapAndUpSpacingS; @lapAndUpSpacingM; @lapAndUpSpacingL; @lapAndUpSpacingXL; @lapAndUpSpacingXXL) !important;
}

@media @mediaQueryDeskOnly {
	.spacing(@prefixDesk; padding; @lapAndUpSpacingStandard; @lapAndUpSpacingXS; @lapAndUpSpacingS; @lapAndUpSpacingM; @lapAndUpSpacingL; @lapAndUpSpacingXL; @lapAndUpSpacingXXL) !important;
}

// Gap

.gap(@empty; @palmSpacingStandard; @palmSpacingXS; @palmSpacingS; @palmSpacingM; @palmSpacingL; @palmSpacingXL; @palmSpacingXXL) !important;

@media @mediaQueryLapAndUp {
	.gap(@empty; @lapAndUpSpacingStandard; @lapAndUpSpacingXS; @lapAndUpSpacingS; @lapAndUpSpacingM; @lapAndUpSpacingL; @lapAndUpSpacingXL; @lapAndUpSpacingXXL) !important;
}

@media @mediaQueryPalmOnly {
	.gap(@prefixPalm; @palmSpacingStandard; @palmSpacingXS; @palmSpacingS; @palmSpacingM; @palmSpacingL; @palmSpacingXL; @palmSpacingXXL) !important;
}

@media @mediaQueryLapOnly {
	.gap(@prefixLap; @lapAndUpSpacingStandard; @lapAndUpSpacingXS; @lapAndUpSpacingS; @lapAndUpSpacingM; @lapAndUpSpacingL; @lapAndUpSpacingXL; @lapAndUpSpacingXXL) !important;
}

@media @mediaQueryDeskOnly {
	.gap(@prefixDesk; @lapAndUpSpacingStandard; @lapAndUpSpacingXS; @lapAndUpSpacingS; @lapAndUpSpacingM; @lapAndUpSpacingL; @lapAndUpSpacingXL; @lapAndUpSpacingXXL) !important;
}
