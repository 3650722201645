// --- Borders ---

// resets
/* stylelint-disable */
.border-none, .border-top-none,    .border-vertical-none   { border-top:    none !important; }
.border-none, .border-right-none,  .border-horizontal-none { border-right:  none !important; }
.border-none, .border-bottom-none, .border-vertical-none   { border-bottom: none !important; }
.border-none, .border-left-none,   .border-horizontal-none { border-left:   none !important; }

// border positions
// sets transparent borders to build on with border color classes
.border                            { border:        @borderWidthStandard solid @borderColorStandard !important; }
.border-top,    .border-vertical   { border-top:    @borderWidthStandard solid @borderColorStandard !important; }
.border-right,  .border-horizontal { border-right:  @borderWidthStandard solid @borderColorStandard !important; }
.border-bottom, .border-vertical   { border-bottom: @borderWidthStandard solid @borderColorStandard !important; }
.border-left,   .border-horizontal { border-left:   @borderWidthStandard solid @borderColorStandard !important; }

.border-error   { border-color: @signalColorErrorDark !important; }
.border-warning { border-color: @signalColorWarningDark !important; }
.border-info    { border-color: @signalColorInfoDark !important; }
.border-confirm { border-color: @signalColorConfirmDark !important; }

// border style
// side-specific classes are necessary - setting border styles on sides without borders will generate unwanted borders
.border-solid  { border-style: solid !important; }
.border-dotted { border-style: dotted !important; }

.border-top-solid,     .border-vertical-solid    { border-top-style:    solid !important; }
.border-right-solid,   .border-horizontal-solid  { border-right-style:  solid !important; }
.border-bottom-solid,  .border-vertical-solid    { border-bottom-style: solid !important; }
.border-left-solid,    .border-horizontal-solid  { border-left-style:   solid !important; }
.border-top-dotted,    .border-vertical-dotted   { border-top-style:    dotted !important; }
.border-right-dotted,  .border-horizontal-dotted { border-right-style:  dotted !important; }
.border-bottom-dotted, .border-vertical-dotted   { border-bottom-style: dotted !important; }
.border-left-dotted,   .border-horizontal-dotted { border-left-style:   dotted !important; }
/* stylelint-enable */
