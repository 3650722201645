@star-rating-small-font:		16px;
@star-rating-medium-font:		24px;
@star-rating-large-font:		40px;
@star-rating-color: 			#F2CA26;

.star-rating-edit {
	color: @star-rating-color;
	i:hover {
		transform: scale(1.1);
	}
}

.star-rating-btn {
	border: 0;
	border-radius: 0;
	background: transparent;
	color: inherit;
	white-space: nowrap;
	text-decoration: none;
	padding: 0;
	margin: 0;
	cursor: pointer;
}

.star-rating-read-only {
	.star-rating-btn {
		cursor: default;
	}
}

.star-rating-small {
	font-size: @star-rating-small-font;
	& svg{
		width: @star-rating-small-font;
		height: @star-rating-small-font;
	}
}

.star-rating-medium {
	font-size: @star-rating-medium-font;
	& svg {
		width: @star-rating-medium-font;
		height: @star-rating-medium-font;
	}
}

.star-rating-large {
	font-size: @star-rating-large-font;
	& svg {
		width: @star-rating-large-font;
		height: @star-rating-large-font;
	}
}

.star-rating-icon {
	color: @star-rating-color;
	i {
		display: inline-block;
	}
}

