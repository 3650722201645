@import "./_mixins/general.less";

/* --- White Header --- */

.page-header--white {
	padding: @headerVerticalPaddingPalm;
	white-space: nowrap;
	background-color: @white;

	.page-header__logo,
	.page-header__brand {
		display: inline-block;
		vertical-align: middle;
		white-space: normal;
	}

	.page-header__logo {
		margin-right: @headerBrandImageSpacing;
	}
	.page-header__logo > img {
		display: block;
		width: @headerBrandImageSmallWidth;
		height: @headerBrandImageSmallHeight; // height explicitly set to avoid strange scaling in certain browsers when using SVG
	}

	.page-header__brand {
		max-width: 70%; // approximated width to keep text from spilling over boundaries
		.font-size-rem(@palmFontSizeS);
		line-height: 1.333; // fix for removing 1.333 as standard line-height to 1.6

		color: @color-primary-theme-cosma;
	}
}

@media @mediaQueryLapAndUp {
	.page-header--white {
		.page-header__logo > img {
			width: @headerBrandImageLargeWidth;
			height: @headerBrandImageLargeHeight;
		}

		.page-header__brand {
			.font-size-rem(@lapAndUpFontSizeM);
		}
	}
}

/* --- White Header - Composite --- */
// to be used with top navigation

.page-header--white--composite {
	padding: @headerVerticalPaddingPalm 0;
	height: @headerHeightPalm;

	.page-header__logo > img {
		width:  @headerBrandImageWidthPalm;
		height: @headerBrandImageHeightPalm;
	}

	.page-header__brand {
		width: @headerBrandClaimWidth;
		font-size: @headerBrandClaimFontSizePalm;
		line-height: 1.2;
		font-weight: 600;
		color: @color-secondary-theme-cosma;
	}
}

@media @mediaQueryLapAndUp {
	.page-header--white--composite {
		padding-top: @headerVerticalPaddingLap;
		padding-bottom: @headerVerticalPaddingLap;
		height: @headerHeightLap;

		.page-header__logo > img {
			width:  @headerBrandImageWidthLap;
			height: @headerBrandImageHeightLap;
		}

		.page-header__brand {
			.font-size-rem(@headerBrandClaimFontSizeLap);
		}
	}
}

@media @mediaQueryDeskAndUp {
	.page-header--white--composite {
		padding-top: @headerVerticalPaddingDesk;
		padding-bottom: @headerVerticalPaddingDesk;
		height: @headerHeightDesk;

		.page-header__logo > img {
			width:  @headerBrandImageWidthDesk;
			height: @headerBrandImageHeightDesk;
		}

		.page-header__brand {
			.font-size-rem(@headerBrandClaimFontSizeDesk);
		}
	}
}
