// --- Additional Form Styles ---

// iOS Zoom Fix
// On iOS Safari, when a user interacts with a form input which has a font size below 16px, the browser will zoom in on the page.
// (See http://stackoverflow.com/questions/2989263/disable-auto-zoom-in-input-text-tag-safari-on-iphone)

@formElementMinFontSizePalm: 1.6rem;

@media @mediaQueryPalmOnly {
	.input-text,
	.select,
	.textarea {
		.font-size-rem(@formElementMinFontSizePalm);
	}
	
	
	// Increase default font size for all other text in a form to minimize the number of different font sizes used.
	
	.form {
		.font-size-rem(@formElementMinFontSizePalm);
	}
}