@import "../../../less/_variables/color.less";
@import "../../../less/_mixins/general.less";

.divider {
	margin: 0;
	flex-shrink: 0;
	border-width: 0px 0px 1px 0px;
	border-style: solid;

	.color-scheme(border-color, @gray300, fadeout(@gray100, 88%));
}

.divider-large {
	border-width: 0px 0px 2px;
}

.divider-flex {
	height: auto;
	align-self: stretch;
}
