// --- Advertisements - Additional ---

// Additionals styles for content elements that are affected by advertisements.

// --- Top Navigation ---

// The header needs to be compressed when there is a skyscraper banner so that the two do not overlap.

// Reset to avoid getting mixed up in media queries
@media @mediaQueryDeskAndUp {
	.has-ad-skyscraper--right {
		.top-navigation.fix-to-top--fixed {
			padding: 0;
		}
	}
}
// When the skyscraper is only partially shown, pull the navigation bar towards the left and compress it to minimum size.
@media @mediaQueryCanShowPartialSkyscraper {
	.has-ad-skyscraper--right {
		.top-navigation.fix-to-top--fixed {
			max-width: (@pageWidthMin - 2 * (@pageHorizontalSpacingDeskAndUp + @pageBorderWidth));
			left: (@pageHorizontalSpacingDeskAndUp + @pageBorderWidth);
		}
	}
}
// When the skyscraper is fully shown and the content width needs to be responsive, pull the navigation bar
// towards the left and the right and compress it by applying a right margin (same procedure as with the page wrapper).
@media @mediaQueryCanShowSingleSkyscraperWithCompressedPage {
	.has-ad-skyscraper--right {
		.top-navigation.fix-to-top--fixed {
			left: 0;
			right: 0;
			margin-left: (@pageHorizontalSpacingDeskAndUp + @pageBorderWidth);
			margin-right: (@skyscraperPlacementWidth + (@pageHorizontalSpacingDeskAndUp + @pageBorderWidth));
			max-width: none;
			width: auto;
		}
	}
}
// When the page can be shown in its full width, let the header be positioned naturally.
@media @mediaQueryCanShowSingleSkyscraperWithUncompressedPage {
	.has-ad-skyscraper--right {
		.top-navigation.fix-to-top--fixed {
			left: auto;
			right: auto;
			padding: 0;
			max-width: (@pageWidthMax - (2 * (@pageHorizontalSpacingDeskAndUp + @pageBorderWidth)));
		}
	}
}