@import "_mixins/typography.less";
@import "../js/components/Button/Button.less";

// --- Elements ---

// emphasis
em {
	font-style: italic;
}

strong,
strong input {
	font-weight: 700;
}

// contextual
code {
	font-size: 1em;
	font-family: @fontFamilyMonospace;
}

mark {
	background: none;
	color: inherit;
	font-weight: 700;
}

// super- / subscript
sup,
sub {
	font-size: 0.833em;
	line-height: 0;
}

sup {
	vertical-align: super;
}

sub {
	vertical-align: sub;
}

// links -- referred to as "text buttons" in the design documentation
a {
	.link-text-style();

	&,
	&:link,
	&:visited {
		&:extend(.link-text-style);
	}
}
