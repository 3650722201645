// --- Grid - Flex-Box Layout ---

// There have been efforts from our side to unify .grid and .grid-flex. This is apparently not possible since .grid-flex does not support float and clear which would break a few layouts, among them also our documentation layout.

// This is an opt-in addition to the regular grid layout.
// It fixes a couple of display issues, and allows for some additional flexbox-related layouting.
//
// 1. Fixes faulty positioning of differently sized grid items
// 2. Allows vertical alignment of rows
// 3. Allows CSS-based reordering of grid items


// Grid Container and Basic Layout

.grid-flex {
	.flex-display(flex);
	.flex-flow(row wrap);

	> .grid-item {
		.flex(0 1 auto); // grid items may shrink, but not grow
		width: auto; // reset regular grid's default width of 100% to facilitate working with some of the new flex features
	}

	&:after {
		content: none; // reset clearfix pseudo-element so that it doesn't mess with the layout
	}
}


// Grid Item Alignment - Cross Axis

.grid-align {
	&-start {
		.align-items(flex-start);
		.align-self(flex-start);
	}
	&-end {
		.align-items(flex-end);
		.align-self(flex-end);
	}
	&-center {
		.align-items(center);
		.align-self(center);
	}
	&-baseline {
		.align-items(baseline);
		.align-self(baseline);
	}
	&-stretch {
		.align-items(stretch);
		.align-self(stretch);
	}
}


// Grid Item Alignment - Vertical Axis

.grid-justify {
	&-start {
		.justify-content(flex-start);
	}
	&-end {
		.justify-content(flex-end);
	}
	&-center {
		.justify-content(center);
	}
	&-space-between {
		.justify-content(space-between);
	}
	&-space-around {
		.justify-content(space-around);
	}
}


// Grid Item Grow Factor

.grid-flex {
	& {
		> .grid-item-fixed-width {
			.box-sizing(content-box); // ensures that the absolute width specified for a grid item equals the actual usable content area, regardless of gutters

			// Note: Users should still be able to make fixed-width elements 100% wide in individual states, so long as the "fill-rows" option is activated.
			// Making this state-specific would make the following rule for the "fill-rows" option exponentially more complex.
		}
	}

	&.grid-fill-rows {
		> .grid-item:not(.grid-item-fixed-width) {
			.flex(1 1 auto); // makes all regular grid items consume any remaining whitespace
		}
	}
}


// Flex Item Ordering

.mixin-order(@prefix; @order; @suffix) {
	.@{prefix}order-@{suffix} {
		.flex-order(@order);
	}
}

.mixin-order-block(@prefix) {
	.mixin-order(@prefix; -5; ~"five-up");
	.mixin-order(@prefix; -4; ~"four-up");
	.mixin-order(@prefix; -3; ~"three-up");
	.mixin-order(@prefix; -2; ~"two-up");
	.mixin-order(@prefix; -1; ~"one-up");
	.mixin-order(@prefix; 0; ~"default");
	.mixin-order(@prefix; 1; ~"one-down");
	.mixin-order(@prefix; 2; ~"two-down");
	.mixin-order(@prefix; 3; ~"three-down");
	.mixin-order(@prefix; 4; ~"four-down");
	.mixin-order(@prefix; 5; ~"five-down");
}

.mixin-order-block(@empty);

@media @mediaQueryPalmOnly {
	.mixin-order-block(@prefixPalm);
}
@media @mediaQueryLapOnly {
	.mixin-order-block(@prefixLap);
}
@media @mediaQueryDeskOnly {
	.mixin-order-block(@prefixDesk);
}


// Contained Gutters (no redundant gutters after the last row)

// NOTE: The flex grids behave strangely when they follow non-cleared floated elements (see issue XHTML-619).
// To force-clear the grid container, it is given a width of 100%. However, this value does not take into account the container's negative offset the same way as the `auto` value does.
// Because of this, to stretch the container all the way to the right edge, the grid container needs to be explicitly expanded to neutralize its negative offset via a calculated length value.

// TODO: If this proves to be stable, change the gutters on the standard grid to use "margin-top" to begin with so we don't need to overwrite the bottom margins here

.mixin-flex-grid-gutters(@gutterStandard; @gutterXS; @gutterS; @gutterM; @gutterL; @gutterXL; @gutterXXL) {
	@effectiveWidth: 100%;

	.grid-flex {
		width: @effectiveWidth;

		&.gutter,
		&.gutter-vertical {
			margin-top: -@gutterStandard;

			> .grid-item {
				margin-top: @gutterStandard;
				margin-bottom: 0;
			}
		}

		&.gutter,
		&.gutter-horizontal {
			width: calc(~"@{effectiveWidth} + @{gutterStandard}");
		}

		&.gutter-xs,
		&.gutter-vertical-xs {
			margin-top: -@gutterXS;
			
			> .grid-item {
				margin-top: @gutterXS;
				margin-bottom: 0;
			}
		}
		
		&.gutter-xs,
		&.gutter-horizontal-xs {
			width: calc(~"@{effectiveWidth} + @{gutterXS}");
		}

		&.gutter-s,
		&.gutter-vertical-s {
			margin-top: -@gutterS;

			> .grid-item {
				margin-top: @gutterS;
				margin-bottom: 0;
			}
		}
		
		&.gutter-s,
		&.gutter-horizontal-s {
			width: calc(~"@{effectiveWidth} + @{gutterS}");
		}

		&.gutter-m,
		&.gutter-vertical-m {
			margin-top: -@gutterM;
			
			> .grid-item {
				margin-top: @gutterM;
				margin-bottom: 0;
			}
		}
		
		&.gutter-m,
		&.gutter-horizontal-m {
			width: calc(~"@{effectiveWidth} + @{gutterM}");
		}

		&.gutter-l,
		&.gutter-vertical-l {
			margin-top: -@gutterL;
			
			> .grid-item {
				margin-top: @gutterL;
				margin-bottom: 0;
			}
		}
		
		&.gutter-l,
		&.gutter-horizontal-l {
			width: calc(~"@{effectiveWidth} + @{gutterL}");
		}
		
		&.gutter-xl,
		&.gutter-vertical-xl {
			margin-top: -@gutterXL;
			
			> .grid-item {
				margin-top: @gutterXL;
				margin-bottom: 0;
			}
		}
		
		&.gutter-xl,
		&.gutter-horizontal-xl {
			width: calc(~"@{effectiveWidth} + @{gutterXL}");
		}

		&.gutter-xxl,
		&.gutter-vertical-xxl {
			margin-top: -@gutterXXL;
			
			> .grid-item {
				margin-top: @gutterXXL;
				margin-bottom: 0;
			}
		}

		&.gutter-xxl,
		&.gutter-horizontal-xxl {
			width: calc(~"@{effectiveWidth} + @{gutterXXL}");
		}

		&.gutter-form,
		&.gutter-vertical-form {
			margin-top: -@spacingFormVertical;
			
			> .grid-item {
				margin-top: @spacingFormVertical;
				margin-bottom: 0;
			}
		}
		
		&.gutter-form,
		&.gutter-horizontal-form {
			width: calc(~"@{effectiveWidth} + @{spacingFormHorizontal}");
		}
	}
}

.mixin-flex-grid-gutters(@palmGutterSpacingStandard; @palmSpacingXS; @palmSpacingS; @palmSpacingM; @palmSpacingL; @palmSpacingXL; @palmSpacingXXL);

@media @mediaQueryLapAndUp {
	.mixin-flex-grid-gutters(@lapAndUpGutterSpacingStandard; @lapAndUpSpacingXS; @lapAndUpSpacingS; @lapAndUpSpacingM; @lapAndUpSpacingL; @lapAndUpSpacingXL; @lapAndUpSpacingXXL);
}
