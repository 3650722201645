.tooltip-icon {
	cursor: pointer;
	margin-left: @palmSpacingXS;
	line-height: 1.6rem;
	
	@media @mediaQueryLapAndUp {
		margin-left: @lapAndUpSpacingXS;
	}
	
	&::before {
		font-family: "IS24Icons";
		content: "\e92b";
		font-size: 1.6rem;
		position: relative;
		top: 1px;
		color: @textColorLight;
		
		@media @mediaQueryLapAndUp {
			font-size: 1.6rem;
		}
	}
}
