@import "../Input.less";
@import "../Labels.less";
@import "../../Button/Button.less";
@import "../../../../less/helpers.less";
@import "../../../../less/_mixins/general.less";

// We considered two different approaches of implementing select fields:
// 1. Single select item
// 		Pro: users only have to use a single element
// 		Con: this way we can not use the ::before pseudo-element for the down arrow
// 			symbol which would require us to do the same via a background-image,
// 			requiring another roundtrip to fetch the .svg file
// 2. Select container
// 		Pro: does not require svg background hack
// 		Con: more hassle for the user since they have to include the container
.select-container {

	.select-input-wrapper {

		position: relative;

		&::after {
			font-family: "IS24Icons";
			position: absolute;
			content: "\e914";
			right: @formElementInnerHorizontalMargin;
			top: 0;
			width: @formElementIconSize;
			text-align: right;
			line-height: @formElementHeight;
			font-size: 1.6rem;
			pointer-events: none;
		}

		.select {
			// We need the margin with icon because of the down arrow
			padding-right: @formElementTextWithIconMargin;
			-webkit-appearance: none;
			appearance: none;
			&::-ms-expand {
				display: none;
			}
		}

		&.disabled {
			&::after {
				color: @gray800;
			}
		}

		> .input-icon-left,
		> .input-icon-right {
			position: absolute;
			margin-left: @formElementInnerHorizontalMargin;
			margin-right: @formElementInnerHorizontalMargin;
			color: @color-light-copy;
			font-size: @formElementIconSize;
			line-height: @formElementHeight;
			// Turn icon clicks into clicks on the underlying field
			pointer-events: none;
			z-index: 1;

			+ .select {
				padding-left: @formElementTextWithIconMargin;
			}
		}

		> .input-icon-left {
			~ .input-text {
				padding-left: @formElementTextWithIconMargin;
			}
		}

		> .input-icon-right {
			right: 0;

			~ .input-text {
				padding-right: @formElementTextWithIconMargin;
			}
		}

	}

}

.select {
	// Make sure even select fields that do not use the updated look
	// are displayed as a block.
	display: block;
}

@dropdownLinkIconSpacing: 4px; // TODO swap with @spacingXXS as soon as it's available
@dropDownLinkSpaceForIcon: (@iconFontSize + @dropdownLinkIconSpacing);

.dropdown-link {
	position: relative;
	display: inline-block;

	select {
		// Appearance is not supported by Internet Explorer. We know this, and we took care of Internet Explorer.
		/* doiuse-disable css-appearance */
		-webkit-appearance: none;
		appearance: none;
		/* doiuse-enable css-appearance */
		&::-ms-expand {
			display: none;
		}
	}

	* {
		&:extend(.link-text all);
	}

	&:hover * {
		&:extend(.link-text:hover); // TODO check overwriting of font family
	}

	.dropdown-link-icon + select {
		padding: 0 0 0 @dropDownLinkSpaceForIcon;
	}

	.dropdown-link-icon {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		font-size: @iconFontSize !important;
		width: auto;
		max-width: @iconFontSize;
		height: @iconFontSize;

		pointer-events: none;
		svg{
			height: 1em;
			width: 1em;
			display: block;
		}
	}

	&:hover,
	&:focus,
	&:active {
		text-decoration: none;

		color: @color-primary-theme-cosma;

		option {
			// This is for Windows where the elements inside the dropdown also get the font color of @linkColor
			color: @color-main-copy;
		}
	}
}

.dropdown-link-icon-only {
	select {
		max-width: @dropDownLinkSpaceForIcon;
	}
}
@media @mediaQueryPalmOnly {
	.palm-dropdown-link-icon-only {
		select {
			max-width: @dropDownLinkSpaceForIcon;
		}
	}
}

.dropdown-link,
.dropdown-link-icon-only {
	&.disabled > .dropdown-link-icon,
	&.disabled > select,
	> select:disabled {
		color: @color-main-copy-disabled;
	}
}
