@import "../_mixins/general.less";

// --- Campaign Box ---

// A box with the orange ImmobilienScout24 border, rounded on the bottom right corner.

.campaign-box(@prefix; @borderWidth; @borderRadius; @verticalSpacing; @horizontalSpacing) {
	.@{prefix}campaign-box {
		border-radius: 0 0 @borderRadius 0;
		padding: @verticalSpacing @horizontalSpacing;
		background-color: rgba(red(@white), green(@white), blue(@white), 0.94);
		box-shadow: 0 2px 4px -2px rgba(red(@gray1), green(@gray1), blue(@gray1), 0.4);
		border-width: @borderWidth;
		border-style: solid;
		border-color: @color-primary-theme-cosma;
	}
}
.campaign-box-override(@prefix; @borderWidth; @borderRadius; @verticalSpacing; @horizontalSpacing) {
	.@{prefix}campaign-box {
		border-width: @borderWidth;
		border-radius: 0 0 @borderRadius 0;
		padding: @verticalSpacing @horizontalSpacing;
	}
}

// Regular Campaign Box
.campaign-box(@empty; @campaignBoxBorderWidthPalm; @campaignBoxBorderRadiusPalm; @campaignBoxVerticalPaddingPalm; @campaignBoxHorizontalPaddingPalm);
@media @mediaQueryLapAndUp {
	.campaign-box-override(@empty; @campaignBoxBorderWidthLapAndUp; @campaignBoxBorderRadiusLapAndUp; @campaignBoxVerticalPaddingLapAndUp; @campaignBoxHorizontalPaddingLapAndUp);
}

// State-specific Campaign Box
@media @mediaQueryPalmOnly {
	.campaign-box(@prefixPalm; @campaignBoxBorderWidthPalm; @campaignBoxBorderRadiusPalm; @campaignBoxVerticalPaddingPalm; @campaignBoxHorizontalPaddingPalm);
}
@media @mediaQueryLapOnly {
	.campaign-box(@prefixLap; @campaignBoxBorderWidthLapAndUp; @campaignBoxBorderRadiusLapAndUp; @campaignBoxVerticalPaddingLapAndUp; @campaignBoxHorizontalPaddingLapAndUp);
}
@media @mediaQueryDeskOnly {
	.campaign-box(@prefixDesk; @campaignBoxBorderWidthLapAndUp; @campaignBoxBorderRadiusLapAndUp; @campaignBoxVerticalPaddingLapAndUp; @campaignBoxHorizontalPaddingLapAndUp);
}
