@import "../../../less/_mixins/general.less";
@import "../../../less/_mixins/typography.less";
@import "../../../less/_variables/color-palette.less";
@import "../../../less/_variables/spacing.less";
@import "../../../less/_variables/typography.less";
@import "../../../less/_variables/media-queries.less";
@import "../../../less/_variables/borders.less";

@topBarMaxHeightPalm: 24px;
@topBarMaxHeightLapAndUp: 48px;

@topBarSpacingPalm: @palmSpacingM;
@topBarSpacingLapAndUp: @lapAndUpSpacingM;

@bodySpacingPalm: @palmSpacingS;
@bodySpacingLapAndUp: @lapAndUpSpacingS;

.tile {
	padding: @palmSpacingM;
	border: @borderWidthStandard solid @borderColorStandard;
	border-radius: 8px;
	@parent: tile;

	&:hover {
		.color-scheme(background-color, @gray200, transparent);
	}

	@media @mediaQueryLapAndUp {
		padding: @lapAndUpSpacingM;
	}

	&-top-bar {
		.color-scheme(color, @textColorStandard, @gray400);

		max-height: @topBarMaxHeightPalm;
		display: flex;
		justify-content: space-between;
		gap: @topBarSpacingPalm;
		margin-bottom: @topBarSpacingPalm;

		[class*="is24-icon-"],
		[class*="s24-icons-"] {
			font-size: 24px;

			@media @mediaQueryLapAndUp {
				font-size: 48px;
			}
		}

		svg,
		img {
			max-height: 24px;
			width: auto;

			@media @mediaQueryLapAndUp {
				max-height: 48px;
			}
		}

		@media @mediaQueryLapAndUp {
			max-height: @topBarMaxHeightLapAndUp;
			gap: @topBarSpacingLapAndUp;
			margin-bottom: @topBarSpacingLapAndUp;
		}
	}

	&-top-bar-left,
	&-top-bar-right {
		display: flex;
		align-items: flex-start;
	}

	&-body {
		display: flex;
		flex-direction: column;
		gap: @bodySpacingPalm;

		@media @mediaQueryLapAndUp {
			gap: @bodySpacingLapAndUp;
		}
	}

	&-title {
		.color-scheme(color, @textColorStandard, @gray400);
		.font-ellipsis();

		font-size: @palmFontSize[paragraphTabular];
		line-height: @palmLineHeight[paragraphTabular];
		font-weight: 700;

		@media @mediaQueryLapAndUp {
			font-size: @lapAndUpFontSize[heading5];
			line-height: @lapAndUpLineHeight[heading5];
		}
	}

	&-description {
		.color-scheme(color, @textColorLight, @gray700);

		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}

	&-bottom-bar {
		.color-scheme(color, @textColorStandard, @gray400);

		display: flex;
		font-size: @fontSizeXS;
		margin-top: @bodySpacingPalm;

		@media @mediaQueryLapAndUp {
			font-size: @fontSizeS;
			margin-top: @bodySpacingLapAndUp;
		}
	}

	&-centered {
		padding: 16px;
		display: flex;
		align-items: center;

		&:hover {
			@media @mediaQueryPalmOnly {
				background-color: unset !important; //override style from light/dark mode
			}
		}

		@media @mediaQueryLapAndUp {
			display: block;
		}

		.@{parent}-title {
			text-align: left;

			@media @mediaQueryLapAndUp {
				margin-bottom: @topBarSpacingPalm;
				text-align: center;
			}
		}

		.@{parent}-body {
			flex: 1;
			overflow: hidden;
		}

		.@{parent}-top-bar {
			margin: 0;
			margin-right: @topBarSpacingLapAndUp;
			justify-content: center;

			@media @mediaQueryLapAndUp {
				margin-right: 0;
				margin-bottom: @topBarSpacingLapAndUp;
			}
		}

		.@{parent}-chevron {
			width: 24px;
			height: 24px;
			font-size: 24px;

			@media @mediaQueryLapAndUp {
				display: none;
			}
		}
	}
}
