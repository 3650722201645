// --- Display ---

// Template for display definition blocks
// -- usage:
// -- call with state prefix (@prefixPalm, @prefixLap, @prefixDesk) to create state-specific classes
// -- call with @empty to create generic classes
// -- append "!important" after mixin call to make all classes important

.display(@prefix) {
	.@{prefix}display-none {
		display: none;
	}

	.@{prefix}display-inline {
		display: inline;
	}

	.@{prefix}display-inline-block {
		display: inline-block;
	}

	.@{prefix}display-block {
		display: block;
	}

	.@{prefix}display-table {
		display: table;
	}

	.@{prefix}display-table-cell {
		display: table-cell;
	}

	.@{prefix}display-table-row {
		display: table-row;
	}

	/* doiuse-disable flexbox */
	.@{prefix}display-flex {
		display: flex;
	}

	.@{prefix}display-inline-flex {
		display: inline-flex;
	}
	/* doiuse-enable flexbox */

	.@{prefix}display-grid {
		display: grid;
	}

	.@{prefix}display-inline-grid {
		display: inline-grid;
	}
}

.display(@empty) !important;

@media @mediaQueryPalmOnly {
	.display(@prefixPalm) !important;
}
@media @mediaQueryLapOnly {
	.display(@prefixLap) !important;
}
@media @mediaQueryDeskOnly {
	.display(@prefixDesk) !important;
}
