@import "../../../less/_variables/general.less";
@import "../../../less/_mixins/general.less";

.img-placeholder {
	width: 100%;
	height: 100%;
	display: inline-flex;
	justify-content: center;
	align-items: center;

	.color-scheme(background-color, @gray400, @gray1000);

	& svg {
		.color-scheme(fill, @gray100, @gray850);
	}

	& rect {
		.color-scheme(fill, @gray400, @gray1000);
		.color-scheme(stroke, @gray100, @gray850);
	}
}
