// This file collects all deprecated styles, together with the release they were
// marked as deprecated in.

// fineprint/terms
.fineprint {
	.font-hint();
}

// form themes
.form-theme-white {
	.form-theme-standard();
}

// inputs with unit icons
.input-unit {
	display: inline-block;
	padding-right: 2em;
}

.unit-icon {
	display: inline-block;
	margin-left: -1.5em;
	padding: 0.416em 0;
	width: 1.5em;
	vertical-align: baseline;
	pointer-events: none;
}

.input-checkbox,
.input-radio {
	display: block;
	float: left;
	margin-top: 5px;
}

.label-checkbox,
.label-radio {
	display: block;
	margin-left: 20px;
}
