@import "../_mixins/general.less";

.breadcrumb {
	border-bottom: 1px solid @borderColorStandard;
	padding: @palmSpacingS @palmSpacingXS;
	.font-size-rem(@palmFontSizeS);
}

@media @mediaQueryLapAndUp {
	.breadcrumb {
		padding: @lapAndUpSpacingS @lapAndUpSpacingXS;
		.font-size-rem(@lapAndUpFontSizeS);
	}
}

.breadcrumb::after {
	.clearfix();
}

.breadcrumb__item {
	float: left;

	&::after {
		font-family: "IS24Icons";
		content: "\e916";
		padding: 0 2px;
		color: #757575;
		font-size: 1.2rem;

		@media @mediaQueryLapAndUp {
			padding: 0 6px 0 5px;
		}
	}
}

.breadcrumb__item--current {
	color: @gray1000;

	&::after {
		display: none;
	}
}

.breadcrumb__link,
.breadcrumb__item a {
	&:link,
	&:visited {
		color: @textColorLight;
		text-decoration: none;
	}
	&:hover,
	&:focus,
	&:active {
		color: @textColorLight;
		text-decoration: underline;
	}
}
