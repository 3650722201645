@import "../Input.less";
@import "../Labels.less";
@import "../../../../less/_mixins/general.less";

.selection-control(toggle-switch);

.toggle-switch-container {
	.input-toggle-switch {
		top: @formInputToggleSwitchHeight / 2 - @formInputControlFocusElementSize / 2 + @formInputToggleSwitchVerticalOffset;
		right: @formInputToggleSwitchWidth / 2 - @formInputToggleSwitchFocusElementWidth / 2;
		width: @formInputToggleSwitchFocusElementWidth;
		border-radius: @formInputToggleSwitchBorderRadius;

		&:focus,
		&:active {
			box-shadow: none;
		}

		&:hover + .label-toggle-switch {
			&::before {
				background-color: @gray400;
			}

			&::after {
				width: @formInputToggleSwitchThumbSize + 4;
				margin-right: -4px;
				border-radius: @formInputToggleSwitchBorderRadius;
			}
		}

		&:hover:checked + .label-toggle-switch {
			&::before {
				background-color: @teal400;
			}

			&::after {
				width: @formInputToggleSwitchThumbSize + 4;
				margin-right: 0;
			}
		}

		&:focus + .label-toggle-switch {
			&::before {
				border-color: @gray400;
				background-color: @gray400;
			}
		}

		&:checked + .label-toggle-switch {
			&::before {
				border-color: @teal400;
				background-color: @teal400;
			}

			&::after {
				right: @formInputToggleSwitchThumbMargin;
			}
		}

		&:disabled + .label-toggle-switch {
			&::before {
				border-color: @gray400;
				background-color: @gray400;
			}

			&::after {
				background-color: @gray700;
				width: @formInputToggleSwitchThumbSize;
				margin-right: 0;
				transform: none;
			}
		}

		&:disabled:checked + .label-toggle-switch {
			&::before {
				border-color: @color-border-input-theme-cosma;
				background-color: @gray700;
			}

			&::after {
				background-color: @gray400;
				width: @formInputToggleSwitchThumbSize;
				transform: none;
			}
		}
	}

	.label-toggle-switch {
		position: relative;
		padding-right: @formInputToggleSwitchWidth + @formInputControlMarginBetweenControlAndLabel;

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: @formInputControlPaddingVertical;
		}

		&::before {
			margin-top: @formInputToggleSwitchVerticalOffset;
			right: 0;
			height: ~"calc(@{formInputToggleSwitchHeight} - (2 * @{size-border-toggle-switch}))";
			width: ~"calc(@{formInputToggleSwitchWidth} - (2 * @{size-border-toggle-switch}))";
			transition: background-color @transitionTimeStandard;
			border-radius: @formInputToggleSwitchBorderRadius;
			border-width: @size-border-toggle-switch;
			border-style: solid;

			background-color: @gray600;
			border-color: @gray600;
		}

		&::after {
			// Need to set `top` explicitly because of multi-line labels
			margin-top: @formInputToggleSwitchThumbMargin + @formInputToggleSwitchVerticalOffset;
			right: @formInputToggleSwitchWidth - @formInputToggleSwitchThumbSize - @formInputToggleSwitchThumbMargin;
			transition: all @transitionTimeStandard;
			height: @formInputToggleSwitchThumbSize;
			width: @formInputToggleSwitchThumbSize;
			background-color: @white;
			border-radius: 100%;
			box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
		}
	}
}
