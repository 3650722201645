// --- Links With Visited State ---

// Exclude ".disabled" and buttons to avoid overwriting their specific colors
@visitedLinkStateExcludedSelectors: ~':not(.disabled):not([class*="button"])';

/* stylelint-disable no-duplicate-selectors */
.link-visited {
	// -- Individual (.link-visited class on the link itself)
	
	// Stand-alone links
	a&@{visitedLinkStateExcludedSelectors} {
		&:visited {
			color: @linkVisitedColor;
		}
		&:hover,
		&:focus,
		&:active {
			color: @linkHoverColor;
		}
	}
	
	// when used to wrap headlines
	a&@{visitedLinkStateExcludedSelectors} {
		&:visited {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: @linkVisitedColor;
			}
		}
		&:hover,
		&:focus,
		&:active {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: @linkHoverColor;
			}
		}
	}
	
	
	// -- Nested (link nested within .link-visited)
	
	a@{visitedLinkStateExcludedSelectors} {
		&:visited {
			color: @linkVisitedColor;
		}
		&:hover,
		&:focus,
		&:active {
			color: @linkHoverColor;
		}
	}
	
	// when used to wrap headlines
	a@{visitedLinkStateExcludedSelectors} {
		&:visited {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: @linkVisitedColor;
			}
		}
		&:hover,
		&:focus,
		&:active {
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				color: @linkHoverColor;
			}
		}
	}
}
/* stylelint-enable no-duplicate-selectors */
