// --- powered-by logo ---
@corporatePoweredbyLogoHeightPalm: 16px;
@corporatePoweredbyLogoHeightLap:  @corporatePoweredbyLogoHeightPalm;
@corporatePoweredbyLogoHeightDesk: 19px;

@corporatePoweredByPositionPalm: @palmSpacingXS;
@corporatePoweredByPositionLap:  round(@headerHeightLap  / 2 - @corporatePoweredbyLogoHeightLap  / 2);
@corporatePoweredByPositionDesk: round(@headerHeightDesk / 2 - @corporatePoweredbyLogoHeightDesk / 2);

.corporate-powered-by-container {
	position: relative;
}
.corporate-powered-by {
	position: absolute;
	right: 0;
	top: @corporatePoweredByPositionPalm;
}
.corporate-powered-by:after {
	.clearfix();
}
.corporate-powered-by__logo {
	display: block;
	float: right;
	height: @corporatePoweredbyLogoHeightPalm; // ensures logo can never be taller than reserved space
}

@media @mediaQueryLapAndUp {
	.corporate-powered-by {
		top: @corporatePoweredByPositionLap;
	}
	.corporate-powered-by__logo {
		height: @corporatePoweredbyLogoHeightLap; // ensures logo can never be taller than reserved space
	}
}

@media @mediaQueryDeskAndUp {
	.corporate-powered-by {
		top: @corporatePoweredByPositionDesk;
		right: (@pageHorizontalSpacingDeskAndUp + @pageBorderWidth);
	}
	.corporate-powered-by__logo {
		height: @corporatePoweredbyLogoHeightDesk; // ensures logo can never be taller than reserved space
	}
}