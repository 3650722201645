@import "../../../less/_variables/spacing.less";
@import "../../../less/_variables/media-queries.less";

.page-head {
	display: flex;
	flex-wrap: wrap;

	@media @mediaQueryLapAndUp {
		flex-wrap: nowrap;
		gap: @lapAndUpSpacingL;
	}

	& > * {
		flex: 1;

		@media @mediaQueryPalmOnly {
			flex: 100%;
		}
	}

	&-title {
		margin: 0;
		font-weight: 700 !important;
	}

	&-subtitle {
		margin: 0;
		margin-top: @palmSpacingM;

		@media @mediaQueryLapAndUp {
			margin-top: @lapAndUpSpacingM;
		}
	}

	&-btn {
		margin: 0;
		margin-bottom: @palmSpacingM;

		@media @mediaQueryLapAndUp {
			margin-bottom: @lapAndUpSpacingM;
		}
	}

	&-btn ~ &-subtitle {
		margin-top: @palmSpacingS;

		@media @mediaQueryLapAndUp {
			margin-top: @lapAndUpSpacingS;
		}
	}

	&-content {
		display: block;

		@media @mediaQueryPalmOnly {
			margin-top: @palmSpacingL;
		}
	}
}
