@import "../../../less/_variables/buttons.less";
@import "../../../less/_variables/color-palette.less";
@import "../../../less/_variables/typography.less";
@import "../../../less/_variables/borders.less";
@import "../../../less/_mixins/general.less";
@import "../../../less/helpers.less";

// Basic buttons

.button,
.button-primary,
.button-secondary,
.button-tertiary,
.button-inverted {
	display: inline-block;
	border: @borderWidthStandard solid transparent;
	border-radius: 8px;
	padding: @buttonPaddingVertical @buttonPaddingHorizontal;
	font-weight: @buttonFontWeight;
	font-size: @buttonFontSize;
	text-align: center;
	letter-spacing: @buttonLetterSpacing;
	line-height: @buttonLineHeight;
	white-space: nowrap;
	cursor: pointer;
	text-decoration: none;
	// Make sure iOS doesn't mess up our <input type="submit"> buttons. While we're at it, let's safeguard all available types of buttons against any styling from the browser.
	// Appearance is not supported by Internet Explorer. We know this, and we took care of Internet Explorer.
	/* doiuse-disable css-appearance */
	-webkit-appearance: none;
	appearance: none;
	/* doiuse-enable css-appearance */
	min-width: @buttonMinWidth;
	font-family: @fontFamilyStandard-theme-cosma;
	position: relative;

	&:hover,
	&:focus {
		text-decoration: none;
	}

	&.button-small {
		padding-top: @buttonPaddingVerticalSmall;
		padding-bottom: @buttonPaddingVerticalSmall;
		min-width: @buttonMinWidthBig;
	}

	&.button-large {
		padding-top: @buttonPaddingVerticalLarge;
		padding-bottom: @buttonPaddingVerticalLarge;
		min-width: @buttonMinWidthBig;
	}

	&[disabled],
	&.disabled {
		cursor: default;
		color: @color-disabled-button-text-theme-cosma;
		background-color: @color-disabled-button-background-theme-cosma;
		border-color: @color-disabled-button-border-theme-cosma;

		&:hover,
		&:link,
		&:visited {
			color: @color-disabled-button-text-theme-cosma;
			background-color: @color-disabled-button-background-theme-cosma;
			box-shadow: none;
			border-color: @color-disabled-button-border-theme-cosma;
		}
	}

	a& {
		font-size: @buttonFontSize;
		font-weight: @buttonFontWeight;
		text-decoration: none;
	}
}

// Default button shares styles with inverted ghost button when dark mode is disabled
.button,
.button-inverted {
	&,
	&:link,
	&:visited {
		background-color: transparent;
		color: @color-ghost-theme-cosma;
		border-color: @color-button-ghost-border-theme-cosma;
		text-decoration: none;
		.color-scheme-dark(background-color, @gray1300);
		.color-scheme-dark(color, @white);
		.color-scheme-dark(border-color, @gray1000);
	}

	&:hover {
		color: @color-ghost-theme-cosma;
		box-shadow: none;
		background-color: @gray300;
	}
}

.button-primary {
	&,
	&:link,
	&:visited {
		color: @color-button-text-theme-cosma;
		border-color: @color-brand-theme-cosma;
		background-color: @color-brand-theme-cosma;
	}

	&:hover {
		color: @color-button-text-theme-cosma;
		border-color: @color-primary-hover-theme-cosma;
		background-color: @color-primary-hover-theme-cosma;
	}
}

.button-secondary {
	&,
	&:link,
	&:visited {
		color: @color-button-secondary-text-theme-cosma;
		border-color: @color-secondary-theme-cosma;
		background-color: @color-secondary-theme-cosma;
	}

	&:hover {
		color: @color-button-secondary-text-theme-cosma;
		border-color: @color-secondary-hover-theme-cosma;
		background-color: @color-secondary-hover-theme-cosma;
	}
}

.button-tertiary {
	&,
	&:link,
	&:visited {
		color: @color-button-text-theme-cosma;
		background-color: @color-button-tertiary-background-theme-cosma;
		border: none;
	}

	&:hover {
		color: @color-button-text-theme-cosma;
		background-color: @color-button-tertiary-hover-background-theme-cosma;
		border: none;
	}
}

.button-inverted {
	&,
	&:link,
	&:visited {
		.color-scheme-dark(color, @color-inverted-button-text);
		.color-scheme-dark(border-color, @color-inverted-button-border);
		.color-scheme-dark(background-color, transparent);
	}

	&:hover {
		.color-scheme-dark(color, @color-inverted-button-text);
		.color-scheme-dark(border-color, @color-inverted-button-hover-border);
		.color-scheme-dark(background-color, transparent);
	}
}

.button-icon-standalone {
	font-size: 1em;
	.link-text();
	text-decoration: none;

	&-small {
		letter-spacing: 0;
		font-size: 1.4rem;
		padding-left: 8px;
		padding-right: 8px;
		padding-top: 8px;
		padding-bottom: 8px;
		min-width: 0;
		vertical-align: bottom;
		line-height: 1;
	}

	&-medium {
		letter-spacing: 0;
		padding-left: 9px;
		padding-right: 9px;
		padding-top: 9px;
		padding-bottom: 9px;
		min-width: 0;
		vertical-align: bottom;
		line-height: 1;

		[class*="is24-icon"],
		& [class*=" s24-icons-"],
		& [class^="s24-icons-"],
		& svg {
			font-size: 2rem;
		}
	}

	&-large {
		letter-spacing: 0;
		padding-left: 13px;
		padding-right: 13px;
		padding-top: 13px;
		padding-bottom: 13px;
		min-width: 0;
		vertical-align: bottom;
		line-height: 1;

		[class*="is24-icon"],
		[class*=" s24-icons-"],
		[class^="s24-icons-"],
		& svg {
			font-size: 2rem;
		}
	}

	& span {
		font-size: @fontSizeXL;
	}

	background: transparent;
	border: none;
	min-width: 0;

	&:hover {
		box-shadow: none;
		border: none;
		background: none;
		border-color: @color-secondary-theme-cosma;
		text-decoration: none;
	}
}

// Link buttons

.link-button-base() {
	cursor: pointer;
	text-decoration: none;
	font-family: inherit;
	font-weight: 400;
	appearance: none;
	border: none;
	background-color: transparent;
	outline: none;
	padding-left: 0;
	padding-right: 0;

	&:hover,
	&:focus:focus-visible,
	&:active {
		text-decoration: underline;
	}

	&[disabled],
	&.disabled {
		cursor: default;
		text-decoration: none;
		color: @color-main-copy-disabled;
	}
}

.button-link {
	.link-button-base();

	color: @linkColor;
}

.button-text {
	.link-button-base();

	color: @color-main-copy;
}

.button-text-light {
	.link-button-base();

	color: @color-light-copy;
}

.link-text-style() {
	// deprecated
	text-decoration: none;
	font-weight: 400;
	cursor: pointer;
	color: @blue600;

	&:hover,
	&:focus,
	&:active {
		color: @blue600;
		text-decoration: @decoration-button-standaloneicon-theme-cosma;
	}

	&[disabled],
	&.disabled {
		color: @color-main-copy-disabled;
	}

	&.link-underline {
		text-decoration: underline;
	}
}

.link-text {
	// deprecated
	.link-text-style();
	// Appearance is not supported by Internet Explorer. We have checked Internet Explorer and it behaves the way we expect it to.
	/* doiuse-disable css-appearance */
	-webkit-appearance: none;
	appearance: none;
	/* doiuse-enable css-appearance */
	border: none;
	background-color: transparent;
	outline: none;

	font-family: @fontFamilyStandard-theme-cosma;
	color: @blue600;
}

.link-text-secondary {
	// deprecated
	&:extend(.link-text);
	color: @color-secondary-theme-cosma;

	&:hover,
	&:focus,
	&:active {
		text-decoration: underline;
		color: @color-secondary-theme-cosma;
	}
}

.button-loading[disabled]:not(.button-link):not(.button-text):not(.button-text-light) {
	display: inline-flex;
	justify-content: center;
	align-items: center;

	&,
	&:hover {
		border-color: transparent;
		background-color: @gray300;
	}

	&::before {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: @gray300;
		border-radius: 8px;
		border-color: transparent;
		z-index: 1;
	}
}

.button-loader {
	position: absolute;
	z-index: 1;
}

div.button-tooltip {
	display: inline-block;
	width: auto;
	height: auto;
	vertical-align: bottom;
}

// Utilities

.button-margin-top {
	margin-top: @buttonMarginVertical !important;
}

.button-margin-bottom {
	margin-bottom: @buttonMarginVertical !important;
}

.button-margin-vertical {
	.button-margin-top();
	.button-margin-bottom();
}

.button-margin-left {
	margin-left: @buttonMarginHorizontal !important;
}

.button-margin-right {
	margin-right: @buttonMarginHorizontal !important;
}

.button-margin-horizontal {
	.button-margin-left();
	.button-margin-right();
}

.button-margin {
	.button-margin-horizontal();
	.button-margin-vertical();
}

.button-icon-margin-right {
	margin-right: 8px;
}

.button-icon-margin-left {
	margin-left: 8px;
}
