// --- Grid ---

// --- Mixins ---

// templates for grid classes
// -- usage:
// -- pass state prefix
// ---- @prefixPalm | @prefixLap | @prefixDesk to create state-specific classes
// ---- @empty to create generic classes
// -- pass size variables to adjust gutter widths for specific states

.grid-gutters(@gutterStandard; @gutterXS; @gutterS; @gutterM; @gutterL; @gutterXL; @gutterXXL) {
	// gutters
	
	.gutter,
	.gutter-horizontal {
		margin-left: -@gutterStandard;
	}
	.gutter > .grid-item,
	.gutter-vertical > .grid-item {
		margin-bottom: @gutterStandard;
	}
	.gutter > .grid-item,
	.gutter-horizontal > .grid-item {
		padding-left: @gutterStandard;
	}
	
	.gutter-xs,
	.gutter-horizontal-xs {
		margin-left: -@gutterXS;
	}
	.gutter-xs > .grid-item,
	.gutter-vertical-xs > .grid-item {
		margin-bottom: @gutterXS;
	}
	.gutter-xs > .grid-item,
	.gutter-horizontal-xs > .grid-item {
		padding-left: @gutterXS;
	}
	
	.gutter-s,
	.gutter-horizontal-s {
		margin-left: -@gutterS;
	}
	.gutter-s > .grid-item,
	.gutter-vertical-s > .grid-item {
		margin-bottom: @gutterS;
	}
	.gutter-s > .grid-item,
	.gutter-horizontal-s > .grid-item {
		padding-left: @gutterS;
	}
	
	.gutter-m,
	.gutter-horizontal-m {
		margin-left: -@gutterM;
	}
	.gutter-m > .grid-item,
	.gutter-vertical-m > .grid-item {
		margin-bottom: @gutterM;
	}
	.gutter-m > .grid-item,
	.gutter-horizontal-m > .grid-item {
		padding-left: @gutterM;
	}
	
	.gutter-l,
	.gutter-horizontal-l {
		margin-left: -@gutterL;
	}
	.gutter-l > .grid-item,
	.gutter-vertical-l > .grid-item {
		margin-bottom: @gutterL;
	}
	.gutter-l > .grid-item,
	.gutter-horizontal-l > .grid-item {
		padding-left: @gutterL;
	}
	
	.gutter-xl,
	.gutter-horizontal-xl {
		margin-left: -@gutterXL;
	}
	.gutter-xl > .grid-item,
	.gutter-vertical-xl > .grid-item {
		margin-bottom: @gutterXL;
	}
	.gutter-xl > .grid-item,
	.gutter-horizontal-xl > .grid-item {
		padding-left: @gutterXL;
	}
	
	.gutter-xxl,
	.gutter-horizontal-xxl {
		margin-left: -@gutterXXL;
	}
	.gutter-xxl > .grid-item,
	.gutter-vertical-xxl > .grid-item {
		margin-bottom: @gutterXXL;
	}
	.gutter-xxl > .grid-item,
	.gutter-horizontal-xxl > .grid-item {
		padding-left: @gutterXXL;
	}
	
	.gutter-form,
	.gutter-horizontal-form {
		margin-left: -@spacingFormHorizontal;
	}

	.gutter-form > .grid-item,
	.gutter-vertical-form > .grid-item {
		margin-bottom: @spacingFormVertical;
	}

	.gutter-form > .grid-item,
	.gutter-horizontal-form > .grid-item {
		padding-left: @spacingFormHorizontal;
	}
}

.grid-push(@prefix) {
	// push classes (offset)
	
	// The plural s in these is confusing for some developers, so we're making it optional.
	
	// halves
	.@{prefix}push-one-half,
	.@{prefix}push-two-fourth,
	.@{prefix}push-two-fourths,
	.@{prefix}push-three-sixth,
	.@{prefix}push-three-sixths,
	.@{prefix}push-four-eighth,
	.@{prefix}push-four-eighths,
	.@{prefix}push-five-tenth,
	.@{prefix}push-five-tenths,
	.@{prefix}push-six-twelfth,
	.@{prefix}push-six-twelfths {
		margin-left: 50%;
	}
	
	// thirds
	.@{prefix}push-one-third,
	.@{prefix}push-two-sixth,
	.@{prefix}push-two-sixths,
	.@{prefix}push-four-twelfth,
	.@{prefix}push-four-twelfths {
		margin-left: 33.333%;
	}

	.@{prefix}push-two-third,
	.@{prefix}push-two-thirds,
	.@{prefix}push-four-sixth,
	.@{prefix}push-four-sixths,
	.@{prefix}push-eight-twelfth,
	.@{prefix}push-eight-twelfths {
		margin-left: 66.666%;
	}
	
	// fourths
	.@{prefix}push-one-fourth,
	.@{prefix}push-two-eighth,
	.@{prefix}push-two-eighths,
	.@{prefix}push-three-twelfth,
	.@{prefix}push-three-twelfths {
		margin-left: 25%;
	}

	.@{prefix}push-three-fourth,
	.@{prefix}push-three-fourths,
	.@{prefix}push-six-eighth,
	.@{prefix}push-six-eighths,
	.@{prefix}push-nine-twelfth,
	.@{prefix}push-nine-twelfths {
		margin-left: 75%;
	}
	
	// fifths
	.@{prefix}push-one-fifth,
	.@{prefix}push-two-tenth,
	.@{prefix}push-two-tenths {
		margin-left: 20%;
	}

	.@{prefix}push-two-fifth,
	.@{prefix}push-two-fifths,
	.@{prefix}push-four-tenth,
	.@{prefix}push-four-tenths {
		margin-left: 40%;
	}

	.@{prefix}push-three-fifth,
	.@{prefix}push-three-fifths,
	.@{prefix}push-six-tenth,
	.@{prefix}push-six-tenths {
		margin-left: 60%;
	}

	.@{prefix}push-four-fifth,
	.@{prefix}push-four-fifths,
	.@{prefix}push-eight-tenth,
	.@{prefix}push-eight-tenths {
		margin-left: 80%;
	}
	
	// sixths
	.@{prefix}push-one-sixth,
	.@{prefix}push-two-twelfth,
	.@{prefix}push-two-twelfths {
		margin-left: 16.666%;
	}

	.@{prefix}push-five-sixth,
	.@{prefix}push-five-sixths,
	.@{prefix}push-ten-twelfth,
	.@{prefix}push-ten-twelfths {
		margin-left: 83.333%;
	}
	
	// eighths
	.@{prefix}push-one-eighth {
		margin-left: 12.5%;
	}
	
	.@{prefix}push-three-eighth,
	.@{prefix}push-three-eighths {
		margin-left: 37.5%;
	}

	.@{prefix}push-five-eighth,
	.@{prefix}push-five-eighths {
		margin-left: 62.5%;
	}

	.@{prefix}push-seven-eighth,
	.@{prefix}push-seven-eighths {
		margin-left: 87.5%;
	}
	
	// tenths
	.@{prefix}push-one-tenth {
		margin-left: 10%;
	}

	.@{prefix}push-three-tenth,
	.@{prefix}push-three-tenths {
		margin-left: 30%;
	}

	.@{prefix}push-seven-tenth,
	.@{prefix}push-seven-tenths {
		margin-left: 70%;
	}

	.@{prefix}push-nine-tenth,
	.@{prefix}push-nine-tenths {
		margin-left: 90%;
	}
	
	// twelfths
	.@{prefix}push-one-twelfth {
		margin-left: 8.333%;
	}

	.@{prefix}push-five-twelfth,
	.@{prefix}push-five-twelfths {
		margin-left: 41.666%;
	}

	.@{prefix}push-seven-twelfth,
	.@{prefix}push-seven-twelfths {
		margin-left: 58.333%;
	}

	.@{prefix}push-ten-twelfth,
	.@{prefix}push-ten-twelfths {
		margin-left: 83.333%;
	}

	.@{prefix}push-eleven-twelfth,
	.@{prefix}push-eleven-twelfths {
		margin-left: 91.666%;
	}
}

// --- Styles

// grid containers
.grid {
	display: block;
	margin-right: 0;
}

.grid:after {
	.clearfix()
}

// grid items
.grid-item {
	display: block;
	float: left;
	width: 100%;
	vertical-align: top;
	text-align: left;
}

// non-prefixed gutter classes
.grid-gutters(@palmGutterSpacingStandard; @palmSpacingXS; @palmSpacingS; @palmSpacingM; @palmSpacingL; @palmSpacingXL; @palmSpacingXXL);
@media @mediaQueryLapAndUp {
	.grid-gutters(@lapAndUpGutterSpacingStandard; @lapAndUpSpacingXS; @lapAndUpSpacingS; @lapAndUpSpacingM; @lapAndUpSpacingL; @lapAndUpSpacingXL; @lapAndUpSpacingXXL);
}

// state-prefixed push classes
.grid-push(@empty);
@media @mediaQueryPalmOnly {
	.grid-push(@prefixPalm);
}
@media @mediaQueryLapOnly {
	.grid-push(@prefixLap);
}
@media @mediaQueryDeskOnly {
	.grid-push(@prefixDesk);
}
