@import "../../../less/_variables/spacing.less";
@import "../../../less/_variables/color.less";

.breadcrumbs {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;

	&-item {
		display: flex;
		align-items: center;

		&:not(:last-child) {
			color: @gray850;
		}

		&:last-child {
			pointer-events: none;

			.breadcrumbs-separator {
				display: none;
			}
		}
	}

	&-separator {
		width: 16px;
		height: 16px;
		font-size: 16px;
		color: @gray1000;
		text-decoration: none;

		&::before {
			display: inline-block; // removed underline on CSS version
			text-decoration: none;
		}
	}
}
