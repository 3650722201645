@import "variables";

[class^="is24-icon-"], [class*=" is24-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '@{icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.is24-icon-rating-star-empty {
	&:before {
		content: @is24-icon-rating-star-empty;
	}
}
.is24-icon-rating-star-half {
	&:before {
		content: @is24-icon-rating-star-half;
	}
}
.is24-icon-rating-star-full {
	&:before {
		content: @is24-icon-rating-star-full;
	}
}
.is24-icon-water {
	&:before {
		content: @is24-icon-water;
	}
}
.is24-icon-tree {
	&:before {
		content: @is24-icon-tree;
	}
}
.is24-icon-offer {
	&:before {
		content: @is24-icon-offer;
	}
}
.is24-icon-dealer {
	&:before {
		content: @is24-icon-dealer;
	}
}
.is24-icon-ec .path1 {
	&:before {
		content: @is24-icon-ec-path1;
		color: rgb(0, 127, 195);
	}
}
.is24-icon-ec .path2 {
	&:before {
		content: @is24-icon-ec-path2;
		margin-left: -1em;
		color: rgb(255, 0, 0);
	}
}
.is24-icon-ec .path3 {
	&:before {
		content: @is24-icon-ec-path3;
		margin-left: -1em;
		color: rgb(41, 37, 38);
	}
}
.is24-icon-paypal .path1 {
	&:before {
		content: @is24-icon-paypal-path1;
		color: rgb(0, 157, 223);
	}
}
.is24-icon-paypal .path2 {
	&:before {
		content: @is24-icon-paypal-path2;
		margin-left: -1em;
		color: rgb(0, 49, 135);
	}
}
.is24-icon-facebook {
	&:before {
		content: @is24-icon-facebook;
		color: #1877f2;
	}
}
.is24-icon-linkedin {
	&:before {
		content: @is24-icon-linkedin;
		color: #0b66c2;
	}
}
.is24-icon-rss {
	&:before {
		content: @is24-icon-rss;
		color: #ff9015;
	}
}
.is24-icon-twitter {
	&:before {
		content: @is24-icon-twitter;
		color: #1da1f2;
	}
}
.is24-icon-whatsapp {
	&:before {
		content: @is24-icon-whatsapp;
		color: #24d366;
	}
}
.is24-icon-xing {
	&:before {
		content: @is24-icon-xing;
		color: #017679;
	}
}
.is24-icon-youtube {
	&:before {
		content: @is24-icon-youtube;
		color: #f00;
	}
}
.is24-icon-bar {
	&:before {
		content: @is24-icon-bar;
	}
}
.is24-icon-building-pharmacy {
	&:before {
		content: @is24-icon-building-pharmacy;
	}
}
.is24-icon-hospital {
	&:before {
		content: @is24-icon-hospital;
	}
}
.is24-icon-public-transport-subway {
	&:before {
		content: @is24-icon-public-transport-subway;
	}
}
.is24-icon-restaurant {
	&:before {
		content: @is24-icon-restaurant;
	}
}
.is24-icon-gas-pump {
	&:before {
		content: @is24-icon-gas-pump;
	}
}
.is24-icon-married-couple {
	&:before {
		content: @is24-icon-married-couple;
	}
}
.is24-icon-fuel-efficient-tree {
	&:before {
		content: @is24-icon-fuel-efficient-tree;
	}
}
.is24-icon-mouse-navigation-360 {
	&:before {
		content: @is24-icon-mouse-navigation-360;
	}
}
.is24-icon-premium-2 {
	&:before {
		content: @is24-icon-premium-2;
	}
}
.is24-icon-premium {
	&:before {
		content: @is24-icon-premium;
	}
}
.is24-icon-random {
	&:before {
		content: @is24-icon-random;
	}
}
.is24-icon-sporty {
	&:before {
		content: @is24-icon-sporty;
	}
}
.is24-icon-trucktype-bus {
	&:before {
		content: @is24-icon-trucktype-bus;
	}
}
.is24-icon-video {
	&:before {
		content: @is24-icon-video;
	}
}
.is24-icon-virtualtour {
	&:before {
		content: @is24-icon-virtualtour;
	}
}
.is24-icon-chat-availability {
	&:before {
		content: @is24-icon-chat-availability;
	}
}
.is24-icon-chat-question-mark {
	&:before {
		content: @is24-icon-chat-question-mark;
	}
}
.is24-icon-chat-exclamation-mark {
	&:before {
		content: @is24-icon-chat-exclamation-mark;
	}
}
.is24-icon-note-glyph {
	&:before {
		content: @is24-icon-note-glyph;
	}
}
.is24-icon-note-with-pen {
	&:before {
		content: @is24-icon-note-with-pen;
	}
}
.is24-icon-data-security {
	&:before {
		content: @is24-icon-data-security;
	}
}
.is24-icon-note {
	&:before {
		content: @is24-icon-note;
	}
}
.is24-icon-pdf {
	&:before {
		content: @is24-icon-pdf;
	}
}
.is24-icon-service-security-check {
	&:before {
		content: @is24-icon-service-security-check;
	}
}
.is24-icon-instagram {
	&:before {
		content: @is24-icon-instagram;
	}
}
.is24-icon-smart-home-iot {
	&:before {
		content: @is24-icon-smart-home-iot;
	}
}
.is24-icon-as24-motorcycle {
	&:before {
		content: @is24-icon-as24-motorcycle;
	}
}
.is24-icon-as24-trucks {
	&:before {
		content: @is24-icon-as24-trucks;
	}
}
.is24-icon-baggage {
	&:before {
		content: @is24-icon-baggage;
	}
}
.is24-icon-cappucino {
	&:before {
		content: @is24-icon-cappucino;
	}
}
.is24-icon-cinema {
	&:before {
		content: @is24-icon-cinema;
	}
}
.is24-icon-education {
	&:before {
		content: @is24-icon-education;
	}
}
.is24-icon-expandable {
	&:before {
		content: @is24-icon-expandable;
	}
}
.is24-icon-info1 {
	&:before {
		content: @is24-icon-info1;
	}
}
.is24-icon-ipad {
	&:before {
		content: @is24-icon-ipad;
	}
}
.is24-icon-is24-mobile {
	&:before {
		content: @is24-icon-is24-mobile;
	}
}
.is24-icon-paint-roller-glyph {
	&:before {
		content: @is24-icon-paint-roller-glyph;
	}
}
.is24-icon-paint-roller {
	&:before {
		content: @is24-icon-paint-roller;
	}
}
.is24-icon-percent-glyph {
	&:before {
		content: @is24-icon-percent-glyph;
	}
}
.is24-icon-percent1 {
	&:before {
		content: @is24-icon-percent1;
	}
}
.is24-icon-pin-glyph {
	&:before {
		content: @is24-icon-pin-glyph;
	}
}
.is24-icon-pin {
	&:before {
		content: @is24-icon-pin;
	}
}
.is24-icon-price {
	&:before {
		content: @is24-icon-price;
	}
}
.is24-icon-radio-glyph {
	&:before {
		content: @is24-icon-radio-glyph;
	}
}
.is24-icon-radio {
	&:before {
		content: @is24-icon-radio;
	}
}
.is24-icon-search-map {
	&:before {
		content: @is24-icon-search-map;
	}
}
.is24-icon-targeting {
	&:before {
		content: @is24-icon-targeting;
	}
}
.is24-icon-time-glyph {
	&:before {
		content: @is24-icon-time-glyph;
	}
}
.is24-icon-time {
	&:before {
		content: @is24-icon-time;
	}
}
.is24-icon-trash-glyph {
	&:before {
		content: @is24-icon-trash-glyph;
	}
}
.is24-icon-trash {
	&:before {
		content: @is24-icon-trash;
	}
}
.is24-icon-save-search-heart {
	&:before {
		content: @is24-icon-save-search-heart;
	}
}
.is24-icon-filter-glyph {
	&:before {
		content: @is24-icon-filter-glyph;
	}
}
.is24-icon-advertising {
	&:before {
		content: @is24-icon-advertising;
	}
}
.is24-icon-apartment-house {
	&:before {
		content: @is24-icon-apartment-house;
	}
}
.is24-icon-armchair {
	&:before {
		content: @is24-icon-armchair;
	}
}
.is24-icon-ballpoint-pen {
	&:before {
		content: @is24-icon-ballpoint-pen;
	}
}
.is24-icon-book {
	&:before {
		content: @is24-icon-book;
	}
}
.is24-icon-booklet-additional-24px-380-copy-3 {
	&:before {
		content: @is24-icon-booklet-additional-24px-380-copy-3;
	}
}
.is24-icon-bulls-eye {
	&:before {
		content: @is24-icon-bulls-eye;
	}
}
.is24-icon-camera-option {
	&:before {
		content: @is24-icon-camera-option;
	}
}
.is24-icon-camera {
	&:before {
		content: @is24-icon-camera;
	}
}
.is24-icon-chat {
	&:before {
		content: @is24-icon-chat;
	}
}
.is24-icon-commode {
	&:before {
		content: @is24-icon-commode;
	}
}
.is24-icon-construction-helmet {
	&:before {
		content: @is24-icon-construction-helmet;
	}
}
.is24-icon-contrast {
	&:before {
		content: @is24-icon-contrast;
	}
}
.is24-icon-cup {
	&:before {
		content: @is24-icon-cup;
	}
}
.is24-icon-distance {
	&:before {
		content: @is24-icon-distance;
	}
}
.is24-icon-document-empty {
	&:before {
		content: @is24-icon-document-empty;
	}
}
.is24-icon-downsize {
	&:before {
		content: @is24-icon-downsize;
	}
}
.is24-icon-enlarge {
	&:before {
		content: @is24-icon-enlarge;
	}
}
.is24-icon-entrance {
	&:before {
		content: @is24-icon-entrance;
	}
}
.is24-icon-envelope {
	&:before {
		content: @is24-icon-envelope;
	}
}
.is24-icon-ground-floor {
	&:before {
		content: @is24-icon-ground-floor;
	}
}
.is24-icon-home {
	&:before {
		content: @is24-icon-home;
	}
}
.is24-icon-house {
	&:before {
		content: @is24-icon-house;
	}
}
.is24-icon-id {
	&:before {
		content: @is24-icon-id;
	}
}
.is24-icon-link {
	&:before {
		content: @is24-icon-link;
	}
}
.is24-icon-living-space-option {
	&:before {
		content: @is24-icon-living-space-option;
	}
}
.is24-icon-location-pin {
	&:before {
		content: @is24-icon-location-pin;
	}
}
.is24-icon-minus {
	&:before {
		content: @is24-icon-minus;
	}
}
.is24-icon-monitor {
	&:before {
		content: @is24-icon-monitor;
	}
}
.is24-icon-navigation {
	&:before {
		content: @is24-icon-navigation;
	}
}
.is24-icon-new-apartment-building {
	&:before {
		content: @is24-icon-new-apartment-building;
	}
}
.is24-icon-notebook {
	&:before {
		content: @is24-icon-notebook;
	}
}
.is24-icon-paperclip {
	&:before {
		content: @is24-icon-paperclip;
	}
}
.is24-icon-paperplane {
	&:before {
		content: @is24-icon-paperplane;
	}
}
.is24-icon-percent {
	&:before {
		content: @is24-icon-percent;
	}
}
.is24-icon-picture {
	&:before {
		content: @is24-icon-picture;
	}
}
.is24-icon-plus {
	&:before {
		content: @is24-icon-plus;
	}
}
.is24-icon-price-check {
	&:before {
		content: @is24-icon-price-check;
	}
}
.is24-icon-quote-close {
	&:before {
		content: @is24-icon-quote-close;
	}
}
.is24-icon-quote-open {
	&:before {
		content: @is24-icon-quote-open;
	}
}
.is24-icon-roadworks {
	&:before {
		content: @is24-icon-roadworks;
	}
}
.is24-icon-room-size {
	&:before {
		content: @is24-icon-room-size;
	}
}
.is24-icon-shopping-basket {
	&:before {
		content: @is24-icon-shopping-basket;
	}
}
.is24-icon-sos {
	&:before {
		content: @is24-icon-sos;
	}
}
.is24-icon-stopwatch {
	&:before {
		content: @is24-icon-stopwatch;
	}
}
.is24-icon-terrace-house {
	&:before {
		content: @is24-icon-terrace-house;
	}
}
.is24-icon-text-aligned-left-2 {
	&:before {
		content: @is24-icon-text-aligned-left-2;
	}
}
.is24-icon-text-aligned-left {
	&:before {
		content: @is24-icon-text-aligned-left;
	}
}
.is24-icon-text {
	&:before {
		content: @is24-icon-text;
	}
}
.is24-icon-umbrella {
	&:before {
		content: @is24-icon-umbrella;
	}
}
.is24-icon-icon-warning {
	&:before {
		content: @is24-icon-icon-warning;
	}
}
.is24-icon-accept-glyph {
	&:before {
		content: @is24-icon-accept-glyph;
	}
}
.is24-icon-accept {
	&:before {
		content: @is24-icon-accept;
	}
}
.is24-icon-add-glyph {
	&:before {
		content: @is24-icon-add-glyph;
	}
}
.is24-icon-add {
	&:before {
		content: @is24-icon-add;
	}
}
.is24-icon-answer {
	&:before {
		content: @is24-icon-answer;
	}
}
.is24-icon-archive-glyph {
	&:before {
		content: @is24-icon-archive-glyph;
	}
}
.is24-icon-archive {
	&:before {
		content: @is24-icon-archive;
	}
}
.is24-icon-arrow-down {
	&:before {
		content: @is24-icon-arrow-down;
	}
}
.is24-icon-arrow-left {
	&:before {
		content: @is24-icon-arrow-left;
	}
}
.is24-icon-arrow-right {
	&:before {
		content: @is24-icon-arrow-right;
	}
}
.is24-icon-arrow-up {
	&:before {
		content: @is24-icon-arrow-up;
	}
}
.is24-icon-attach {
	&:before {
		content: @is24-icon-attach;
	}
}
.is24-icon-calendar-appointments-move-in-date {
	&:before {
		content: @is24-icon-calendar-appointments-move-in-date;
	}
}
.is24-icon-calendar-check {
	&:before {
		content: @is24-icon-calendar-check;
	}
}
.is24-icon-calendar-x {
	&:before {
		content: @is24-icon-calendar-x;
	}
}
.is24-icon-calendar {
	&:before {
		content: @is24-icon-calendar;
	}
}
.is24-icon-cancel-glyph {
	&:before {
		content: @is24-icon-cancel-glyph;
	}
}
.is24-icon-cancel {
	&:before {
		content: @is24-icon-cancel;
	}
}
.is24-icon-caution-glyph {
	&:before {
		content: @is24-icon-caution-glyph;
	}
}
.is24-icon-caution {
	&:before {
		content: @is24-icon-caution;
	}
}
.is24-icon-chevron-down {
	&:before {
		content: @is24-icon-chevron-down;
	}
}
.is24-icon-chevron-left {
	&:before {
		content: @is24-icon-chevron-left;
	}
}
.is24-icon-chevron-right {
	&:before {
		content: @is24-icon-chevron-right;
	}
}
.is24-icon-chevron-up {
	&:before {
		content: @is24-icon-chevron-up;
	}
}
.is24-icon-closing {
	&:before {
		content: @is24-icon-closing;
	}
}
.is24-icon-collective-response {
	&:before {
		content: @is24-icon-collective-response;
	}
}
.is24-icon-dashboard-glyph {
	&:before {
		content: @is24-icon-dashboard-glyph;
	}
}
.is24-icon-dashboard {
	&:before {
		content: @is24-icon-dashboard;
	}
}
.is24-icon-deactivate {
	&:before {
		content: @is24-icon-deactivate;
	}
}
.is24-icon-delete-glyph {
	&:before {
		content: @is24-icon-delete-glyph;
	}
}
.is24-icon-delete {
	&:before {
		content: @is24-icon-delete;
	}
}
.is24-icon-download {
	&:before {
		content: @is24-icon-download;
	}
}
.is24-icon-draft-glyph {
	&:before {
		content: @is24-icon-draft-glyph;
	}
}
.is24-icon-draft {
	&:before {
		content: @is24-icon-draft;
	}
}
.is24-icon-edit-glyph {
	&:before {
		content: @is24-icon-edit-glyph;
	}
}
.is24-icon-edit {
	&:before {
		content: @is24-icon-edit;
	}
}
.is24-icon-error-glyph {
	&:before {
		content: @is24-icon-error-glyph;
	}
}
.is24-icon-error {
	&:before {
		content: @is24-icon-error;
	}
}
.is24-icon-favorite-like-your-property {
	&:before {
		content: @is24-icon-favorite-like-your-property;
	}
}
.is24-icon-heart-Favorite-glyph {
	&:before {
		content: @is24-icon-heart-Favorite-glyph;
	}
}
.is24-icon-heart-favorite {
	&:before {
		content: @is24-icon-heart-favorite;
	}
}
.is24-icon-highlight-ad {
	&:before {
		content: @is24-icon-highlight-ad;
	}
}
.is24-icon-info-glyph {
	&:before {
		content: @is24-icon-info-glyph;
	}
}
.is24-icon-info {
	&:before {
		content: @is24-icon-info;
	}
}
.is24-icon-lock-glyph {
	&:before {
		content: @is24-icon-lock-glyph;
	}
}
.is24-icon-lock-unlock {
	&:before {
		content: @is24-icon-lock-unlock;
	}
}
.is24-icon-lock {
	&:before {
		content: @is24-icon-lock;
	}
}
.is24-icon-menu-burger-menu {
	&:before {
		content: @is24-icon-menu-burger-menu;
	}
}
.is24-icon-menu-sub-menu {
	&:before {
		content: @is24-icon-menu-sub-menu;
	}
}
.is24-icon-message-email {
	&:before {
		content: @is24-icon-message-email;
	}
}
.is24-icon-message-glyph {
	&:before {
		content: @is24-icon-message-glyph;
	}
}
.is24-icon-message-read {
	&:before {
		content: @is24-icon-message-read;
	}
}
.is24-icon-message-sent {
	&:before {
		content: @is24-icon-message-sent;
	}
}
.is24-icon-message-unread {
	&:before {
		content: @is24-icon-message-unread;
	}
}
.is24-icon-message-unsent {
	&:before {
		content: @is24-icon-message-unsent;
	}
}
.is24-icon-message {
	&:before {
		content: @is24-icon-message;
	}
}
.is24-icon-move-ad-up {
	&:before {
		content: @is24-icon-move-ad-up;
	}
}
.is24-icon-notification-alert-glyph {
	&:before {
		content: @is24-icon-notification-alert-glyph;
	}
}
.is24-icon-notification-alert {
	&:before {
		content: @is24-icon-notification-alert;
	}
}
.is24-icon-password-hide {
	&:before {
		content: @is24-icon-password-hide;
	}
}
.is24-icon-password-show {
	&:before {
		content: @is24-icon-password-show;
	}
}
.is24-icon-payment-cash-glyph {
	&:before {
		content: @is24-icon-payment-cash-glyph;
	}
}
.is24-icon-payment-cash {
	&:before {
		content: @is24-icon-payment-cash;
	}
}
.is24-icon-payment-credit-card-glyph {
	&:before {
		content: @is24-icon-payment-credit-card-glyph;
	}
}
.is24-icon-payment-credit-card {
	&:before {
		content: @is24-icon-payment-credit-card;
	}
}
.is24-icon-payment-invoice {
	&:before {
		content: @is24-icon-payment-invoice;
	}
}
.is24-icon-persons {
	&:before {
		content: @is24-icon-persons;
	}
}
.is24-icon-positive-check {
	&:before {
		content: @is24-icon-positive-check;
	}
}
.is24-icon-printer-glyph {
	&:before {
		content: @is24-icon-printer-glyph;
	}
}
.is24-icon-printer {
	&:before {
		content: @is24-icon-printer;
	}
}
.is24-icon-profile {
	&:before {
		content: @is24-icon-profile;
	}
}
.is24-icon-question-mark-glyph {
	&:before {
		content: @is24-icon-question-mark-glyph;
	}
}
.is24-icon-question-mark {
	&:before {
		content: @is24-icon-question-mark;
	}
}
.is24-icon-rating-state-1 {
	&:before {
		content: @is24-icon-rating-state-1;
	}
}
.is24-icon-rating-state-2 {
	&:before {
		content: @is24-icon-rating-state-2;
	}
}
.is24-icon-rating-state-3 {
	&:before {
		content: @is24-icon-rating-state-3;
	}
}
.is24-icon-rearrange-option {
	&:before {
		content: @is24-icon-rearrange-option;
	}
}
.is24-icon-rearrange {
	&:before {
		content: @is24-icon-rearrange;
	}
}
.is24-icon-remove-glyph {
	&:before {
		content: @is24-icon-remove-glyph;
	}
}
.is24-icon-remove {
	&:before {
		content: @is24-icon-remove;
	}
}
.is24-icon-save-search-glyph {
	&:before {
		content: @is24-icon-save-search-glyph;
	}
}
.is24-icon-save-search-option-glyph {
	&:before {
		content: @is24-icon-save-search-option-glyph;
	}
}
.is24-icon-save-search-option {
	&:before {
		content: @is24-icon-save-search-option;
	}
}
.is24-icon-save-search {
	&:before {
		content: @is24-icon-save-search;
	}
}
.is24-icon-search-glyph {
	&:before {
		content: @is24-icon-search-glyph;
	}
}
.is24-icon-search {
	&:before {
		content: @is24-icon-search;
	}
}
.is24-icon-settings-glyph {
	&:before {
		content: @is24-icon-settings-glyph;
	}
}
.is24-icon-settings {
	&:before {
		content: @is24-icon-settings;
	}
}
.is24-icon-share-glyph {
	&:before {
		content: @is24-icon-share-glyph;
	}
}
.is24-icon-share {
	&:before {
		content: @is24-icon-share;
	}
}
.is24-icon-show-password-glyph {
	&:before {
		content: @is24-icon-show-password-glyph;
	}
}
.is24-icon-spam-glyph {
	&:before {
		content: @is24-icon-spam-glyph;
	}
}
.is24-icon-spam {
	&:before {
		content: @is24-icon-spam;
	}
}
.is24-icon-telephone-glyph {
	&:before {
		content: @is24-icon-telephone-glyph;
	}
}
.is24-icon-telephone-option-glyph {
	&:before {
		content: @is24-icon-telephone-option-glyph;
	}
}
.is24-icon-telephone-option {
	&:before {
		content: @is24-icon-telephone-option;
	}
}
.is24-icon-telephone {
	&:before {
		content: @is24-icon-telephone;
	}
}
.is24-icon-unlock-glyph {
	&:before {
		content: @is24-icon-unlock-glyph;
	}
}
.is24-icon-unread-glyph {
	&:before {
		content: @is24-icon-unread-glyph;
	}
}
.is24-icon-upgrade-ad-glyph {
	&:before {
		content: @is24-icon-upgrade-ad-glyph;
	}
}
.is24-icon-upgrade-ad {
	&:before {
		content: @is24-icon-upgrade-ad;
	}
}
.is24-icon-upload {
	&:before {
		content: @is24-icon-upload;
	}
}
.is24-icon-warning-glyph {
	&:before {
		content: @is24-icon-warning-glyph;
	}
}
.is24-icon-warning {
	&:before {
		content: @is24-icon-warning;
	}
}
.is24-icon-write-message-glyph {
	&:before {
		content: @is24-icon-write-message-glyph;
	}
}
.is24-icon-write-message {
	&:before {
		content: @is24-icon-write-message;
	}
}
.is24-icon-apartment-option-2-glyph {
	&:before {
		content: @is24-icon-apartment-option-2-glyph;
	}
}
.is24-icon-apartment-option-2 {
	&:before {
		content: @is24-icon-apartment-option-2;
	}
}
.is24-icon-apartment-option-glyph {
	&:before {
		content: @is24-icon-apartment-option-glyph;
	}
}
.is24-icon-apartment-option {
	&:before {
		content: @is24-icon-apartment-option;
	}
}
.is24-icon-application-documents {
	&:before {
		content: @is24-icon-application-documents;
	}
}
.is24-icon-bakeries {
	&:before {
		content: @is24-icon-bakeries;
	}
}
.is24-icon-balcony-glyph {
	&:before {
		content: @is24-icon-balcony-glyph;
	}
}
.is24-icon-balcony {
	&:before {
		content: @is24-icon-balcony;
	}
}
.is24-icon-book-with-euro-sign {
	&:before {
		content: @is24-icon-book-with-euro-sign;
	}
}
.is24-icon-booklet-folder-glyph {
	&:before {
		content: @is24-icon-booklet-folder-glyph;
	}
}
.is24-icon-booklet-folder {
	&:before {
		content: @is24-icon-booklet-folder;
	}
}
.is24-icon-building-apartment-glyph {
	&:before {
		content: @is24-icon-building-apartment-glyph;
	}
}
.is24-icon-building-apartment {
	&:before {
		content: @is24-icon-building-apartment;
	}
}
.is24-icon-building-assembled-house {
	&:before {
		content: @is24-icon-building-assembled-house;
	}
}
.is24-icon-building-bungalow {
	&:before {
		content: @is24-icon-building-bungalow;
	}
}
.is24-icon-building-business-glyph {
	&:before {
		content: @is24-icon-building-business-glyph;
	}
}
.is24-icon-building-business {
	&:before {
		content: @is24-icon-building-business;
	}
}
.is24-icon-building-new-apartment-building {
	&:before {
		content: @is24-icon-building-new-apartment-building;
	}
}
.is24-icon-building-new-building {
	&:before {
		content: @is24-icon-building-new-building;
	}
}
.is24-icon-building-prefab-house {
	&:before {
		content: @is24-icon-building-prefab-house;
	}
}
.is24-icon-building-semi-detached-house {
	&:before {
		content: @is24-icon-building-semi-detached-house;
	}
}
.is24-icon-building-single-family-house {
	&:before {
		content: @is24-icon-building-single-family-house;
	}
}
.is24-icon-building-solid-construction {
	&:before {
		content: @is24-icon-building-solid-construction;
	}
}
.is24-icon-building-two-family-house {
	&:before {
		content: @is24-icon-building-two-family-house;
	}
}
.is24-icon-calculator {
	&:before {
		content: @is24-icon-calculator;
	}
}
.is24-icon-car-park {
	&:before {
		content: @is24-icon-car-park;
	}
}
.is24-icon-city-district {
	&:before {
		content: @is24-icon-city-district;
	}
}
.is24-icon-doctors {
	&:before {
		content: @is24-icon-doctors;
	}
}
.is24-icon-documents-with-plus-symbol {
	&:before {
		content: @is24-icon-documents-with-plus-symbol;
	}
}
.is24-icon-elevator {
	&:before {
		content: @is24-icon-elevator;
	}
}
.is24-icon-financing-option-glyph {
	&:before {
		content: @is24-icon-financing-option-glyph;
	}
}
.is24-icon-financing-option {
	&:before {
		content: @is24-icon-financing-option;
	}
}
.is24-icon-financing {
	&:before {
		content: @is24-icon-financing;
	}
}
.is24-icon-find-location {
	&:before {
		content: @is24-icon-find-location;
	}
}
.is24-icon-fitted-kitchen-glyph {
	&:before {
		content: @is24-icon-fitted-kitchen-glyph;
	}
}
.is24-icon-fitted-kitchen {
	&:before {
		content: @is24-icon-fitted-kitchen;
	}
}
.is24-icon-garden-glyph {
	&:before {
		content: @is24-icon-garden-glyph;
	}
}
.is24-icon-garden {
	&:before {
		content: @is24-icon-garden;
	}
}
.is24-icon-geo-location {
	&:before {
		content: @is24-icon-geo-location;
	}
}
.is24-icon-grocery {
	&:before {
		content: @is24-icon-grocery;
	}
}
.is24-icon-home-dwelling {
	&:before {
		content: @is24-icon-home-dwelling;
	}
}
.is24-icon-house-attic {
	&:before {
		content: @is24-icon-house-attic;
	}
}
.is24-icon-house-basement {
	&:before {
		content: @is24-icon-house-basement;
	}
}
.is24-icon-house-ground-floor {
	&:before {
		content: @is24-icon-house-ground-floor;
	}
}
.is24-icon-house-loft-glyph {
	&:before {
		content: @is24-icon-house-loft-glyph;
	}
}
.is24-icon-house-loft-option {
	&:before {
		content: @is24-icon-house-loft-option;
	}
}
.is24-icon-house-loft {
	&:before {
		content: @is24-icon-house-loft;
	}
}
.is24-icon-house-maisonette {
	&:before {
		content: @is24-icon-house-maisonette;
	}
}
.is24-icon-house-with-loupe {
	&:before {
		content: @is24-icon-house-with-loupe;
	}
}
.is24-icon-household-size {
	&:before {
		content: @is24-icon-household-size;
	}
}
.is24-icon-household-with-euro-sign {
	&:before {
		content: @is24-icon-household-with-euro-sign;
	}
}
.is24-icon-household-with-percent-icon {
	&:before {
		content: @is24-icon-household-with-percent-icon;
	}
}
.is24-icon-immo-ad {
	&:before {
		content: @is24-icon-immo-ad;
	}
}
.is24-icon-immo-rating {
	&:before {
		content: @is24-icon-immo-rating;
	}
}
.is24-icon-internet {
	&:before {
		content: @is24-icon-internet;
	}
}
.is24-icon-key {
	&:before {
		content: @is24-icon-key;
	}
}
.is24-icon-kindergarden-day-care {
	&:before {
		content: @is24-icon-kindergarden-day-care;
	}
}
.is24-icon-land-plot-house-build-glyph {
	&:before {
		content: @is24-icon-land-plot-house-build-glyph;
	}
}
.is24-icon-land-plot-house-build {
	&:before {
		content: @is24-icon-land-plot-house-build;
	}
}
.is24-icon-legal-advice {
	&:before {
		content: @is24-icon-legal-advice;
	}
}
.is24-icon-list-view-compare {
	&:before {
		content: @is24-icon-list-view-compare;
	}
}
.is24-icon-location-glyph {
	&:before {
		content: @is24-icon-location-glyph;
	}
}
.is24-icon-location {
	&:before {
		content: @is24-icon-location;
	}
}
.is24-icon-map-glyph {
	&:before {
		content: @is24-icon-map-glyph;
	}
}
.is24-icon-map {
	&:before {
		content: @is24-icon-map;
	}
}
.is24-icon-mobile-communication {
	&:before {
		content: @is24-icon-mobile-communication;
	}
}
.is24-icon-move-in {
	&:before {
		content: @is24-icon-move-in;
	}
}
.is24-icon-move-out {
	&:before {
		content: @is24-icon-move-out;
	}
}
.is24-icon-moving-planner-glyph {
	&:before {
		content: @is24-icon-moving-planner-glyph;
	}
}
.is24-icon-moving-planner {
	&:before {
		content: @is24-icon-moving-planner;
	}
}
.is24-icon-moving {
	&:before {
		content: @is24-icon-moving;
	}
}
.is24-icon-noise-level-option-glyph {
	&:before {
		content: @is24-icon-noise-level-option-glyph;
	}
}
.is24-icon-noise-level-option {
	&:before {
		content: @is24-icon-noise-level-option;
	}
}
.is24-icon-noise-level {
	&:before {
		content: @is24-icon-noise-level;
	}
}
.is24-icon-number-of-rooms {
	&:before {
		content: @is24-icon-number-of-rooms;
	}
}
.is24-icon-occupancy {
	&:before {
		content: @is24-icon-occupancy;
	}
}
.is24-icon-owner-plus-glyph {
	&:before {
		content: @is24-icon-owner-plus-glyph;
	}
}
.is24-icon-owner-plus {
	&:before {
		content: @is24-icon-owner-plus;
	}
}
.is24-icon-pedestrian-as24 {
	&:before {
		content: @is24-icon-pedestrian-as24;
	}
}
.is24-icon-person-with-checkmark {
	&:before {
		content: @is24-icon-person-with-checkmark;
	}
}
.is24-icon-poll-survey-glyph {
	&:before {
		content: @is24-icon-poll-survey-glyph;
	}
}
.is24-icon-poll-survey {
	&:before {
		content: @is24-icon-poll-survey;
	}
}
.is24-icon-price-map {
	&:before {
		content: @is24-icon-price-map;
	}
}
.is24-icon-price-per-squaremeter {
	&:before {
		content: @is24-icon-price-per-squaremeter;
	}
}
.is24-icon-property-delevoper {
	&:before {
		content: @is24-icon-property-delevoper;
	}
}
.is24-icon-property {
	&:before {
		content: @is24-icon-property;
	}
}
.is24-icon-public-transfort {
	&:before {
		content: @is24-icon-public-transfort;
	}
}
.is24-icon-public-transport-option {
	&:before {
		content: @is24-icon-public-transport-option;
	}
}
.is24-icon-radius-search-glyph {
	&:before {
		content: @is24-icon-radius-search-glyph;
	}
}
.is24-icon-radius-search {
	&:before {
		content: @is24-icon-radius-search;
	}
}
.is24-icon-scale-icon {
	&:before {
		content: @is24-icon-scale-icon;
	}
}
.is24-icon-schools {
	&:before {
		content: @is24-icon-schools;
	}
}
.is24-icon-smiley-icon-favorite-glyph {
	&:before {
		content: @is24-icon-smiley-icon-favorite-glyph;
	}
}
.is24-icon-smiley-icon-favorite {
	&:before {
		content: @is24-icon-smiley-icon-favorite;
	}
}
.is24-icon-smiley-icon-maybe-glyph {
	&:before {
		content: @is24-icon-smiley-icon-maybe-glyph;
	}
}
.is24-icon-smiley-icon-maybe {
	&:before {
		content: @is24-icon-smiley-icon-maybe;
	}
}
.is24-icon-smiley-icon-uninteresting-glyph {
	&:before {
		content: @is24-icon-smiley-icon-uninteresting-glyph;
	}
}
.is24-icon-smiley-icon-uninteresting {
	&:before {
		content: @is24-icon-smiley-icon-uninteresting;
	}
}
.is24-icon-solar-potential-glyph {
	&:before {
		content: @is24-icon-solar-potential-glyph;
	}
}
.is24-icon-solar-potential {
	&:before {
		content: @is24-icon-solar-potential;
	}
}
.is24-icon-statistic-with-arrow-up {
	&:before {
		content: @is24-icon-statistic-with-arrow-up;
	}
}
.is24-icon-statistics-20 {
	&:before {
		content: @is24-icon-statistics-20;
	}
}
.is24-icon-statistics {
	&:before {
		content: @is24-icon-statistics;
	}
}
.is24-icon-travel-time {
	&:before {
		content: @is24-icon-travel-time;
	}
}
.is24-icon-turnkey-ready-to-use {
	&:before {
		content: @is24-icon-turnkey-ready-to-use;
	}
}
.is24-icon-vehicle-bicycle-as24 {
	&:before {
		content: @is24-icon-vehicle-bicycle-as24;
	}
}
.is24-icon-vehicle-car-as24 {
	&:before {
		content: @is24-icon-vehicle-car-as24;
	}
}
.is24-icon-vehicle-taxi {
	&:before {
		content: @is24-icon-vehicle-taxi;
	}
}
.is24-icon-wheelmap {
	&:before {
		content: @is24-icon-wheelmap;
	}
}
.is24-icon-star-loupe {
	&:before {
		content: @is24-icon-star-loupe;
	}
}
.is24-icon-eye-check {
	&:before {
		content: @is24-icon-eye-check;
	}
}
.is24-icon-eye-slash {
	&:before {
		content: @is24-icon-eye-slash;
	}
}
.is24-icon-download-check {
	&:before {
		content: @is24-icon-download-check;
	}
}
.is24-icon-download-slash {
	&:before {
		content: @is24-icon-download-slash;
	}
}
.is24-icon-profi-experten {
	&:before {
		content: @is24-icon-profi-experten;
	}
}

