// --- Lists ---

// template for list spacings
// -- usage:
// -- call with arguments: standard spacing, spacings xs through xxl
// -- append "!important" after mixin call to make all classes important
.list-margins(@spacingStandard; @spacingXS; @spacingS; @spacingM; @spacingL; @spacingXL; @spacingXXL) {
	.list-spacing     > li { margin-bottom: @spacingStandard; }
	.list-spacing-xs  > li { margin-bottom: @spacingXS; }
	.list-spacing-s   > li { margin-bottom: @spacingS; }
	.list-spacing-m   > li { margin-bottom: @spacingM; }
	.list-spacing-l   > li { margin-bottom: @spacingL; }
	.list-spacing-xl  > li { margin-bottom: @spacingXL; }
	.list-spacing-xxl > li { margin-bottom: @spacingXXL; }
}

ol,
.list-bullet {
	overflow: hidden; // for use alongside floating elements
	list-style-position: outside;
}

// additional left spacing for list icons (one, two, three digits)
// todo: does not work with "start" attribute because it is based on the number of child elements
ol li                  { margin-left: 2.333em; } // two-digit default space for IE8 compatibility // todo: simplify as soon as IE8 is out of maintenance
ol li:nth-child(n)     { margin-left: 1.75em;  }
ol li:nth-child(n+10)  { margin-left: 2.333em; }
ol li:nth-child(n+100) { margin-left: 2.916em; }

.list-bullet {
	list-style-type: disc;
}
.list-bullet > li {
	margin-left: 1.333em; // left spacing for list icons
}

.list-check > li {
	position: relative;
	padding-left: 1.5em;
	
	&:before {
		content: "\e943";
		position: absolute;
		left: 0;
		font-family: "IS24Icons";
		color: @textColorStandard;
	}
}

// all devices
.list-margins(@palmSpacingStandard; @palmSpacingXS; @palmSpacingS; @palmSpacingM; @palmSpacingL; @palmSpacingXL; @palmSpacingXXL);

@media @mediaQueryLapAndUp {
	.list-margins(@lapAndUpSpacingStandard; @lapAndUpSpacingXS; @lapAndUpSpacingS; @lapAndUpSpacingM; @lapAndUpSpacingL; @lapAndUpSpacingXL; @lapAndUpSpacingXXL);
}