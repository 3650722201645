@import "../Input.less";
@import "../Labels.less";
@import "../../../../less/_mixins/general.less";

.selection-control(radio);
.selection-control-with-label-on-right(radio);

.radio-container {
	.label-radio {
		position: relative;

		&::before,
		&::after {
			box-sizing: border-box;
		}

		&::before {
			.color-scheme(border-color, #757575, #757575);

			top: @formInputControlPaddingVertical + ((@formInputControlLabelHeight - @formInputRadioSize) / 2);
			width: @formInputRadioSize;
			height: @formInputRadioSize;
			border-radius: 100%;
		}

		&::after {
			.color-scheme(background-color, @white, @gray1100);

			content: "";
			top: @formInputControlPaddingVertical + (@formInputRadioDotSize / 2);
			left: @formInputControlPaddingHorizontal + ((@formInputRadioSize - @formInputRadioDotSize) / 2);
			display: block;
			position: absolute;
			width: @formInputRadioDotSize;
			height: @formInputRadioDotSize;
			border-radius: 100%;
			transition: background-color @transitionTimeStandard, border-color @transitionTimeStandard;
		}
	}

	.input-radio {
		top: 50%;
		transform: translateY(-50%);
		margin-left: @formInputControlPaddingHorizontal + @formInputRadioSize / 2;
		border-radius: 100%;

		&:checked {
			+ .label-radio {
				&::before {
					.color-scheme(border-color, @teal400, #757575);

					background-color: @teal400;
				}

				&::after {
					.color-scheme(background-color, @white, @gray1100);

					box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
				}
			}
		}

		&:focus,
		&:active {
			box-shadow: @box-shadow-radio-button-theme-cosma;
		}

		&:focus:not(:disabled) {
			+ .label-radio {
				&::before {
					border-color: @teal400;
				}
			}
		}

		&:focus:checked:not(:disabled) {
			+ .label-radio {
				&::before {
					border-color: @teal400;
				}
			}
		}

		&:hover:not(:disabled) {
			+ .label-radio {
				&::before {
					.color-scheme(border-color, #757575, #757575);
				}

				&::after {
					.color-scheme(background-color, @white, @gray1100);
				}
			}
		}

		&:hover:checked:not(:disabled) {
			+ .label-radio {
				&::before {
					border-color: @teal400;
				}

				&::after {
					.color-scheme(background-color, @white, @gray1100);
				}
			}
		}

		&:disabled {
			+ .label-radio {
				&::before {
					.color-scheme(border-color, @gray600, #757575);
					.color-scheme(background-color, @gray400, @gray650);
				}

				&::after {
					.color-scheme(background-color, @gray400, @gray650);
				}
			}
		}

		&:disabled:checked {
			+ .label-radio {
				&::before {
					.color-scheme(border-color, @gray600, @color-radio-border-disabled-theme-cosma-dark);
					.color-scheme(background-color, @gray600, @color-radio-background-disabled-theme-cosma-dark);
				}

				&::after {
					.color-scheme(background-color, @white, @gray650);
				}
			}
		}
	}
}
