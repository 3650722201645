@import "../../../less/_mixins/typography.less";
@import "../../../less/_variables/color-palette.less";
@import "../../../less/_variables/general.less";
@import "../../../less/helpers.less";

@progress-bar-circular-s-stroke:	3.4;
@progress-bar-circular-s-font:		18px;
@progress-bar-circular-l-stroke:	2.4;
@progress-bar-circular-l-font:		24px;

@progress-bar-horizontal-s-width:	225px;
@progress-bar-horizontal-l-width:	300px;
@progress-bar-horizontal-font:		14px;

.progress-bar-circular {
	position: relative;

	&.progress-bar-small {
		width: 100px;
		height: 100px;
		.progress-bar-label {
			font-size: @progress-bar-circular-s-font;
		}
	}
	&.progress-bar-large {
		width: 150px;
		height: 150px;
		& circle {
			stroke-width: @progress-bar-circular-l-stroke;
		}
		.progress-bar-label {
			font-size: @progress-bar-circular-l-font;
		}
	}

	.progress-bar-label {
		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		top: 50%;
		font-size: 4vw;
	}

	& circle {
		stroke: @gray300;
		stroke-width: @progress-bar-circular-s-stroke;
		fill: transparent;
		stroke-linecap: round;
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}

	.progress-bar-filled {
		stroke: @green500;
		stroke-dasharray: 100, 100;
		-webkit-transition: 0.35s stroke-dashoffset;
		transition: 0.35s stroke-dashoffset;
		-webkit-transform: rotate(-90deg);
		transform: rotate(-90deg);
	}
}

.progress-bar-horizontal {
	position: relative;
	margin-top: 8px;
	margin-bottom: 8px;

	&.progress-bar-small {
		width: @progress-bar-horizontal-s-width;
	}
	&.progress-bar-large {
		width: @progress-bar-horizontal-l-width;
	}

	.progress-bar-label {
		display: flex;
		flex-direction: row-reverse;
		margin-bottom: 8px;
		font-size: @progress-bar-horizontal-font;
	}

	.progress-bar-overlay {
		position: relative;
		height: 8px;
		border-radius: 4px;
		background-color: @gray300;
		overflow: hidden;
	}

	.progress-bar-filled {
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%;
		border-radius: 4px;
		background-color: @green500;
		position: absolute;
		-webkit-transition: width .5s ease-in-out;
		-moz-transition: width .5s ease-in-out;
		-o-transition: width .5s ease-in-out;
		transition: width .5s ease-in-out;
	}
}
