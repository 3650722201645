@import "../../../less/_variables/media-queries.less";
@import "../../../less/_variables/color-palette.less";
@import "../../../less/_variables/spacing.less";

.carousel {
	&-button {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 48px;
		height: 48px;
		border-radius: 100px;
		background: var(--infographic-day-white, #fff);
		box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.08);
		z-index: 2;

		&-prev {
			left: 20px;
		}

		&-next {
			right: 20px;
		}

		&--disabled {
			display: none;
		}
	}

	&-slide {
		height: 315px;
		width: 263px;
		border-radius: 0.8rem;
		border: 1px #e0e0e0 solid;
		box-sizing: border-box;
		margin: 0 calc(@lapAndUpSpacingL / 2);
	}

	&-dots {
		position: absolute;
		bottom: -@lapAndUpSpacingM;
		display: flex !important;
		justify-content: center;
		width: 100%;
		padding: 0;
		margin: 0;
		list-style: none;

		& > li {
			display: flex;
			align-items: center;
		}
	}

	&-dot {
		box-sizing: border-box;
		height: @lapAndUpSpacingS;
		width: @lapAndUpSpacingS;
		border-radius: 100%;
		background-color: @gray700;
		margin: 0 6px;
		padding: 0;
		cursor: pointer;

		&--active {
			background-color: @teal400;
		}

		&:focus {
			border: 1px solid @teal400;
		}

		&--outlined {
			padding: 16px 0 8px;
			position: relative;
			bottom: 0;

			@media @mediaQueryLapAndUp {
				padding-bottom: 16px;
			}

			.carousel-dot {
				background: rgba(255, 255, 255, 0.5);
				border: 1px solid @gray1000;
			}

			.carousel-dot--active {
				background: @gray1000;
			}
		}
	}
}

:global {
	.slick-slider {
		position: relative;
		display: block;
		box-sizing: border-box;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}
	.slick-list {
		position: relative;
		overflow: hidden;
		display: block;
		margin: 0;
		padding: 0;

		&:focus {
			outline: none;
		}

		&.dragging {
			cursor: pointer;
			cursor: hand;
		}
	}
	.slick-slider .slick-track,
	.slick-slider .slick-list {
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.slick-track {
		position: relative;
		left: 0;
		top: 0;
		display: flex;
		margin-left: auto;
		margin-right: auto;

		&:before,
		&:after {
			content: "";
			display: table;
		}

		&:after {
			clear: both;
		}

		.slick-loading & {
			visibility: hidden;
		}
	}
	.slick-slide {
		float: left;
		height: 100%;
		min-height: 1px;
		[dir="rtl"] & {
			float: right;
		}
		img {
			display: block;
		}
		&.slick-loading img {
			display: none;
		}

		display: none;

		&.dragging img {
			pointer-events: none;
		}

		.slick-initialized & {
			display: block;
		}

		.slick-loading & {
			visibility: hidden;
		}

		.slick-vertical & {
			display: block;
			height: auto;
			border: 1px solid transparent;
		}
	}
	.slick-arrow.slick-hidden {
		display: none;
	}

	.slick-slide {
		display: block;
	}
}
