@import "../../../less/_variables/media-queries.less";
@import "../../../less/_variables/typography.less";
@import "../../../less/_variables/color-palette.less";
@import "../../../less/_variables/spacing.less";
@import "../../../less/_mixins/general.less";

@small: {
	value: 14px;
	valueLineHeight: 22px;

	label: 12px;
	labelLineHeight: 16px;

	valuePalm: 14px;
	valueLineHeightPalm: 22px;
};

@medium: {
	value: 18px;
	valueLineHeight: 28px;

	label: 12px;
	labelLineHeight: 16px;

	valuePalm: 18px;
	valueLineHeightPalm: 28px;
};
@large: {
	value: 24px;
	valueLineHeight: 36px;

	label: 18px;
	labelLineHeight: 28px;

	valuePalm: 20px;
	valueLineHeightPalm: 32px;
};
@extra-large: {
	value: 32px;
	valueLineHeight: 48px;

	label: 18px;
	labelLineHeight: 28px;

	valuePalm: 24px;
	valueLineHeightPalm: 36px;
};

.valueFont(@size, @lineHeight) {
	.value-label_value {
		font-size: @size;
		line-height: @lineHeight;
	}
}

.labelFont(@size, @lineHeight) {
	.value-label_label {
		font-size: @size;
		line-height: @lineHeight;
	}
}

.value-label {
	display: grid;
	grid-template-areas: "icon value" "icon label";
	grid-template-columns: min-content min-content;
	grid-column-gap: @palmSpacingS;
	align-items: start;
	width: min-content;
	.color-scheme(color, @black, @white);

	@media @mediaQueryLapAndUp {
		grid-column-gap: @lapAndUpSpacingS;
	}

	&_label {
		font: @fontFamilyStandard-theme-cosma;
		grid-area: label;
		font-weight: 400;
		.color-scheme(color, @textColorLight, @white);
	}

	&_value {
		font: @fontFamilyStandard-theme-cosma;
		grid-area: value;
		font-weight: 700;
	}

	&_icon {
		grid-area: icon;

		display: flex;
		align-items: center;
	}

	&--alignment {
		&-left {
			justify-items: start;
		}

		&-right {
			justify-items: end;
		}

		&-centered {
			grid-template-areas: "icon" "value" "label";
			justify-items: center;
			grid-template-columns: min-content;

			.value-label_icon {
				margin-bottom: @palmSpacingXS;
			}

			@media @mediaQueryLapAndUp {
				.value-label_icon {
					margin-bottom: @lapAndUpSpacingS;
				}
			}
		}
	}

	&--size {
		.generateSize(@key, @sizeData) {
			&-@{key} {
				.valueFont(@sizeData[valuePalm], @sizeData[valueLineHeightPalm]);
				.labelFont(@sizeData[label], @sizeData[labelLineHeight]);

				@media @mediaQueryLapAndUp {
					.valueFont(@sizeData[value], @sizeData[valueLineHeight]);
				}
			}
		}

		.generateSizes() {
			.generateSize(small, @small);
			.generateSize(medium, @medium);
			.generateSize(large, @large);
			.generateSize(extra-large, @extra-large);
		}

		.generateSizes();
	}
}
