// Typography styles

.text(hint) {
	font-size: var(--font-size-hint);
	line-height: var(--line-height-hint);
}

.text(paragraph) {
	font-size: var(--font-size-paragraph);
	line-height: var(--line-height-paragraph);
}

.text(paragraph-tabular) {
	font-size: var(--font-size-paragraph-tabular);
	line-height: var(--line-height-paragraph-tabular);
}

.text(heading-0) {
	font-size: var(--font-size-heading-0);
	line-height: var(--line-height-heading-0);
	font-weight: 700;
}

.text(heading-1) {
	font-size: var(--font-size-heading-1);
	line-height: var(--line-height-heading-1);
	font-weight: 700;
}

.text(heading-2) {
	font-size: var(--font-size-heading-2);
	line-height: var(--line-height-heading-2);
	font-weight: 700;
}

.text(heading-3) {
	font-size: var(--font-size-heading-3);
	line-height: var(--line-height-heading-3);
	font-weight: 700;
}

.text(heading-4) {
	font-size: var(--font-size-heading-4);
	line-height: var(--line-height-heading-4);
	font-weight: 600;
}

.text(heading-5) {
	font-size: var(--font-size-heading-5);
	line-height: var(--line-height-heading-5);
	font-weight: 600;
}

.font-size-and-line-height-xs() {
	font-size: @fontSizeXS;
	line-height: @lineHeightForFontSizeXS;
}

.font-size-and-line-height-s() {
	font-size: @fontSizeS;
	line-height: @lineHeightForFontSizeS;
}

.font-size-and-line-height-standard() {
	font-size: @fontSizeStandard;
	line-height: @lineHeightForFontSizeStandard;
}

.font-size-and-line-height-m() {
	font-size: @fontSizeM;
	line-height: @lineHeightForFontSizeM;
}

.font-size-and-line-height-l() {
	font-size: @fontSizeL;
	line-height: @lineHeightForFontSizeL;
}

.font-size-and-line-height-xl() {
	font-size: @fontSizeXL;
	line-height: @lineHeightForFontSizeXL;
}

.font-size-and-line-height-xxl() {
	font-size: @fontSizeXXL;
	line-height: @lineHeightForFontSizeXXL;
}

.font-size-and-line-height-xxxl() {
	font-size: @fontSizeXXXL;
	line-height: @lineHeightForFontSizeXXXL;
}

.ui-body-label() {
	line-height: @formInputControlLabelHeight;
	font-size: @fontSizeS;
	font-weight: 600;
}

.font-highlight() {
	font-size: @fontSizeStandard;
	font-weight: 600 !important;
}

.font-tabular() {
	font-size: @fontSizeStandard;
	line-height: @lineHeightS;
}

// Helpers

.font-ellipsis() {
	display: block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: normal;
}
