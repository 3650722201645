@import "../../../less/_variables/spacing.less";
@import "../../../less/_variables/color.less";

.verified-badge {
    display: flex;
    align-items: center;

    &-icon {
        width: 18px;
        height: 18px;
        font-size: 18px;
        color: @gray1000;
        fill: @gray1000;

        &-container {
            margin-left: @lapAndUpSpacingXS;
            display: flex;
            position: relative;
        }

        &-bg {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGQ9Ik0xMiAwTDE0LjY0IDIuMTQ3NTZMMTggMS42MDc3TDE5LjIxMjUgNC43ODc1MUwyMi4zOTIzIDZMMjEuODUyNCA5LjM2MDA1TDI0IDEyTDIxLjg1MjQgMTQuNjRMMjIuMzkyMyAxOEwxOS4yMTI1IDE5LjIxMjVMMTggMjIuMzkyM0wxNC42NCAyMS44NTI0TDEyIDI0TDkuMzYwMDUgMjEuODUyNEw2IDIyLjM5MjNMNC43ODc1MSAxOS4yMTI1TDEuNjA3NyAxOEwyLjE0NzU2IDE0LjY0TDAgMTJMMi4xNDc1NiA5LjM2MDA1TDEuNjA3NyA2TDQuNzg3NTEgNC43ODc1MUw2IDEuNjA3N0w5LjM2MDA1IDIuMTQ3NTZMMTIgMFoiIGZpbGw9IiMwMEQ5QjEiLz4KPC9zdmc+Cg==");
            color: @teal400;
            fill: @teal400;
        }
    }
}
