@import "../Input.less";
@import "../Labels.less";

@import "../../../../less/_variables/borders.less";

.icon-top-offset(@outerSpacing) {
	top: @outerSpacing + (@formInputCheckboxIconSize / 2);
}

.icon-left-offset(@outerSpacing) {
	left: @outerSpacing + ((@formInputCheckboxSize - @formInputCheckboxIconSize) / 2);
}

.icon-container-top-offset(@outerSpacing) {
	top: @outerSpacing + ((@formInputControlLabelHeight - @formInputCheckboxSize) / 2);
}

.selection-control(checkbox);
.selection-control-with-label-on-right(checkbox);

.checkbox-container {
	.label-checkbox {
		position: relative;

		&::before,
		&::after {
			box-sizing: border-box;
		}

		&::before {
			.icon-container-top-offset(@formInputControlPaddingVertical);
			.color-scheme(background-color, @color-checkbox-background-hover-theme-cosma, @color-checkbox-background-hover-theme-cosma-dark);
			.color-scheme(border, @borderWidthStandard solid @color-checkbox-border-theme-cosma, @borderWidthStandard solid @color-checkbox-border-theme-cosma-dark);

			width: @formInputCheckboxSize;
			height: @formInputCheckboxSize;
			border-radius: @borderRadiusStandard;
			transition: ~"border @{transitionTimeStandard}, background @{transitionTimeStandard}";
		}

		&::after {
			.icon-top-offset(@formInputControlPaddingVertical);
			.icon-left-offset(@formInputControlPaddingHorizontal);

			content: "";
			position: absolute;
			width: @formInputCheckboxIconSize;
			height: @formInputCheckboxIconSize;
			border-color: transparent;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	.input-checkbox {
		top: 50%;
		transform: translateY(-50%);
		margin-left: @formInputControlPaddingHorizontal + @formInputCheckboxSize / 2;

		& ~ .label-checkbox.error::before {
			border: 1px solid @color-validation-error-theme-cosma;
		}

		&:hover ~ .label-checkbox.error::before {
			border: 1px solid @color-validation-error-theme-cosma;
		}

		&:hover ~ .label-checkbox::before {
			.color-scheme(background-color, @color-checkbox-background-hover-theme-cosma, @color-checkbox-background-hover-theme-cosma-dark);

			border-color: @color-checkbox-border-hover-theme-cosma;
		}

		&:focus,
		&:active {
			box-shadow: none;
		}

		&:focus ~ .label-checkbox::before {
			box-shadow: 0 0 0 7px fade(@color-checkbox-focus-theme-cosma, 10%);
		}

		&:disabled ~ .label-checkbox::before {
			.color-scheme(background-color, @color-checkbox-background-disabled-theme-cosma, @color-checkbox-background-disabled-theme-cosma-dark);
			.color-scheme(border-color, @color-checkbox-border-disabled-theme-cosma, @color-checkbox-border-disabled-theme-cosma-dark);
		}

		&:checked {
			~ .label-checkbox {
				&:hover::before {
					background-color: @color-background-checkbox-before-checked-hover-theme-cosma;
					border-color: @color-border-checkbox-checked-hover-theme-cosma;
				}
				&::before {
					background-color: @color-background-checkbox-checked-theme-cosma;
					border-color: @color-border-checkbox-checked-theme-cosma;
				}

				&::after {
					.color-scheme(background-image, url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTAgOCI+CiAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNC41NDU0NTQ1NSw4LjcxMTExMTExIEM0LjE4MTgxODE4LDguMzU1NTU1NTYgMy42MzYzNjM2NCw4LjM1NTU1NTU2IDMuMjcyNzI3MjcsOC43MTExMTExMSBDMi45MDkwOTA5MSw5LjA2NjY2NjY3IDIuOTA5MDkwOTEsOS42IDMuMjcyNzI3MjcsOS45NTU1NTU1NiBMNS4wOTA5MDkwOSwxMS43MzMzMzMzIEM1LjI3MjcyNzI3LDExLjkxMTExMTEgNS40NTQ1NDU0NSwxMiA1LjcyNzI3MjczLDEyIEM2LDEyIDYuMTgxODE4MTgsMTEuOTExMTExMSA2LjM2MzYzNjM2LDExLjczMzMzMzMgTDEyLjcyNzI3MjcsNS41MTExMTExMSBDMTMuMDkwOTA5MSw1LjE1NTU1NTU2IDEzLjA5MDkwOTEsNC42MjIyMjIyMiAxMi43MjcyNzI3LDQuMjY2NjY2NjcgQzEyLjM2MzYzNjQsMy45MTExMTExMSAxMS44MTgxODE4LDMuOTExMTExMTEgMTEuNDU0NTQ1NSw0LjI2NjY2NjY3IEw1LjcyNzI3MjczLDkuODY2NjY2NjcgTDQuNTQ1NDU0NTUsOC43MTExMTExMSBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMyAtNCkiLz4KPC9zdmc+Cg=="), url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTAgOCI+CiAgPHBhdGggZmlsbD0iIzI0MjQyNCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNC41NDU0NTQ1NSw4LjcxMTExMTExIEM0LjE4MTgxODE4LDguMzU1NTU1NTYgMy42MzYzNjM2NCw4LjM1NTU1NTU2IDMuMjcyNzI3MjcsOC43MTExMTExMSBDMi45MDkwOTA5MSw5LjA2NjY2NjY3IDIuOTA5MDkwOTEsOS42IDMuMjcyNzI3MjcsOS45NTU1NTU1NiBMNS4wOTA5MDkwOSwxMS43MzMzMzMzIEM1LjI3MjcyNzI3LDExLjkxMTExMTEgNS40NTQ1NDU0NSwxMiA1LjcyNzI3MjczLDEyIEM2LDEyIDYuMTgxODE4MTgsMTEuOTExMTExMSA2LjM2MzYzNjM2LDExLjczMzMzMzMgTDEyLjcyNzI3MjcsNS41MTExMTExMSBDMTMuMDkwOTA5MSw1LjE1NTU1NTU2IDEzLjA5MDkwOTEsNC42MjIyMjIyMiAxMi43MjcyNzI3LDQuMjY2NjY2NjcgQzEyLjM2MzYzNjQsMy45MTExMTExMSAxMS44MTgxODE4LDMuOTExMTExMTEgMTEuNDU0NTQ1NSw0LjI2NjY2NjY3IEw1LjcyNzI3MjczLDkuODY2NjY2NjcgTDQuNTQ1NDU0NTUsOC43MTExMTExMSBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMyAtNCkiLz4KPC9zdmc+Cg=="));
				}
			}

			&:disabled ~ .label-checkbox::before {
				.color-scheme(border-color, @color-checkbox-border-disabled-theme-cosma, @color-checkbox-border-disabled-theme-cosma-dark);
				.color-scheme(background-color, @color-checkbox-background-disabled-theme-cosma, @color-checkbox-background-disabled-theme-cosma-dark);
			}

			&:disabled ~ .label-checkbox::after {
				.color-scheme(background-image, url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTAgOCI+CiAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNC41NDU0NTQ1NSw4LjcxMTExMTExIEM0LjE4MTgxODE4LDguMzU1NTU1NTYgMy42MzYzNjM2NCw4LjM1NTU1NTU2IDMuMjcyNzI3MjcsOC43MTExMTExMSBDMi45MDkwOTA5MSw5LjA2NjY2NjY3IDIuOTA5MDkwOTEsOS42IDMuMjcyNzI3MjcsOS45NTU1NTU1NiBMNS4wOTA5MDkwOSwxMS43MzMzMzMzIEM1LjI3MjcyNzI3LDExLjkxMTExMTEgNS40NTQ1NDU0NSwxMiA1LjcyNzI3MjczLDEyIEM2LDEyIDYuMTgxODE4MTgsMTEuOTExMTExMSA2LjM2MzYzNjM2LDExLjczMzMzMzMgTDEyLjcyNzI3MjcsNS41MTExMTExMSBDMTMuMDkwOTA5MSw1LjE1NTU1NTU2IDEzLjA5MDkwOTEsNC42MjIyMjIyMiAxMi43MjcyNzI3LDQuMjY2NjY2NjcgQzEyLjM2MzYzNjQsMy45MTExMTExMSAxMS44MTgxODE4LDMuOTExMTExMTEgMTEuNDU0NTQ1NSw0LjI2NjY2NjY3IEw1LjcyNzI3MjczLDkuODY2NjY2NjcgTDQuNTQ1NDU0NTUsOC43MTExMTExMSBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMyAtNCkiLz4KPC9zdmc+Cg=="), url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTAgOCI+CiAgPHBhdGggZmlsbD0iI2M0YzRjNCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNC41NDU0NTQ1NSw4LjcxMTExMTExIEM0LjE4MTgxODE4LDguMzU1NTU1NTYgMy42MzYzNjM2NCw4LjM1NTU1NTU2IDMuMjcyNzI3MjcsOC43MTExMTExMSBDMi45MDkwOTA5MSw5LjA2NjY2NjY3IDIuOTA5MDkwOTEsOS42IDMuMjcyNzI3MjcsOS45NTU1NTU1NiBMNS4wOTA5MDkwOSwxMS43MzMzMzMzIEM1LjI3MjcyNzI3LDExLjkxMTExMTEgNS40NTQ1NDU0NSwxMiA1LjcyNzI3MjczLDEyIEM2LDEyIDYuMTgxODE4MTgsMTEuOTExMTExMSA2LjM2MzYzNjM2LDExLjczMzMzMzMgTDEyLjcyNzI3MjcsNS41MTExMTExMSBDMTMuMDkwOTA5MSw1LjE1NTU1NTU2IDEzLjA5MDkwOTEsNC42MjIyMjIyMiAxMi43MjcyNzI3LDQuMjY2NjY2NjcgQzEyLjM2MzYzNjQsMy45MTExMTExMSAxMS44MTgxODE4LDMuOTExMTExMTEgMTEuNDU0NTQ1NSw0LjI2NjY2NjY3IEw1LjcyNzI3MjczLDkuODY2NjY2NjcgTDQuNTQ1NDU0NTUsOC43MTExMTExMSBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMyAtNCkiLz4KPC9zdmc+Cg=="));
			}
		}

		&:indeterminate {
			~ .label-checkbox {
				&:hover::before {
					background-color: @color-background-checkbox-before-checked-hover-theme-cosma;
					border-color: @color-border-checkbox-checked-hover-theme-cosma;
				}
				&::before {
					background-color: @color-background-checkbox-checked-theme-cosma;
					border-color: @color-border-checkbox-checked-theme-cosma;
				}

				&::after {
					.color-scheme(background-image, url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIyIiB2aWV3Qm94PSIwIDAgMTAgMiI+CiAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTIsNyBMNCw3IEMzLjQsNyAzLDcuNCAzLDggQzMsOC42MDAwMDAwMSAzLjQsOSA0LDkgTDEyLDkgQzEyLjYsOSAxMyw4LjYwMDAwMDAxIDEzLDggQzEzLDcuNCAxMi42LDcgMTIsNyBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMyAtNykiLz4KPC9zdmc+Cg=="), url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIyIiB2aWV3Qm94PSIwIDAgMTAgMiI+CiAgPHBhdGggZmlsbD0iIzI0MjQyNCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTIsNyBMNCw3IEMzLjQsNyAzLDcuNCAzLDggQzMsOC42MDAwMDAwMSAzLjQsOSA0LDkgTDEyLDkgQzEyLjYsOSAxMyw4LjYwMDAwMDAxIDEzLDggQzEzLDcuNCAxMi42LDcgMTIsNyBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMyAtNykiLz4KPC9zdmc+Cg=="));
				}
			}

			&:disabled ~ .label-checkbox::before {
				.color-scheme(border-color, @color-checkbox-border-disabled-theme-cosma, @color-checkbox-border-disabled-theme-cosma-dark);
				.color-scheme(background-color, @color-checkbox-background-disabled-theme-cosma, @color-checkbox-background-disabled-theme-cosma-dark);
			}

			&:disabled ~ .label-checkbox::after {
				.color-scheme(background-image, url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIyIiB2aWV3Qm94PSIwIDAgMTAgMiI+CiAgPHBhdGggZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTIsNyBMNCw3IEMzLjQsNyAzLDcuNCAzLDggQzMsOC42MDAwMDAwMSAzLjQsOSA0LDkgTDEyLDkgQzEyLjYsOSAxMyw4LjYwMDAwMDAxIDEzLDggQzEzLDcuNCAxMi42LDcgMTIsNyBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMyAtNykiLz4KPC9zdmc+Cg=="), url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMCIgaGVpZ2h0PSIyIiB2aWV3Qm94PSIwIDAgMTAgMiI+CiAgPHBhdGggZmlsbD0iI2M0YzRjNCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTIsNyBMNCw3IEMzLjQsNyAzLDcuNCAzLDggQzMsOC42MDAwMDAwMSAzLjQsOSA0LDkgTDEyLDkgQzEyLjYsOSAxMyw4LjYwMDAwMDAxIDEzLDggQzEzLDcuNCAxMi42LDcgMTIsNyBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMyAtNykiLz4KPC9zdmc+Cg=="));
			}
		}
	}

	.input-label-helper-text {
		margin-top: 0;
		padding-left: @formInputControlPaddingHorizontal + @formInputCheckboxSize + @formInputControlMarginBetweenControlAndLabel;
	}
}

.checkbox-container--reduced-spacing {
	.label-checkbox {
		padding-top: 0;
		padding-right: 0;
		padding-bottom: 0;
		padding-left: @formInputControlMarginBetweenControlAndLabel + @formInputCheckboxSize;

		&:before {
			.icon-container-top-offset(0);
		}

		&::after {
			.icon-top-offset(0);
			.icon-left-offset(0);
		}
	}

	.input-label-helper-text {
		padding-left: @formInputControlMarginBetweenControlAndLabel + @formInputCheckboxSize;
	}
}
