// --- Page Footer ---

// Reduced Version

.main-footer {
	padding: @palmSpacingM;
	.font-size-rem(@palmFontSizeS);
	line-height: @lineHeightS;
	text-align: center;
	color: @footerColor;
	
	h6 {
		position: absolute;
		left: -9999px;
	}
	
	ul,
	.build-information {
		margin: 0 auto @palmSpacingM;
	}
	
	li {
		display: inline-block;
		margin-left: -0.35em; // counters white space between inline elements - approximation
	}
	li:after {
		content: "|";
	}
	li:last-child:after {
		display: none;
	}
	
	a,
	a:link {
		padding: 0 0.5em;
		color: @footerColor;
	}
}

@media @mediaQueryLapAndUp {
	.main-footer {
		padding: @lapAndUpSpacingM;
		.font-size-rem(@lapAndUpFontSizeS);
		
		ul,
		.build-information {
			margin-bottom: @lapAndUpSpacingS;
			max-width: 610px;
		}
	}
}


// Full Version

.page-footer {
	background: @gray9;
}
.page-footer__accessibility {
	position: absolute;
	left: -9999px;
}
.page-footer__copyright {
	background: @gray1;
	color: @gray5;
}