@import "../../../less/_variables/general.less";
@import "../../../less/_mixins/general.less";
@import "../../../less/_variables/spacing.less";
@import "../../../less/_variables/media-queries.less";

// --- Help Hint ---
// hint message

.help-hint {
	.color-scheme(background-color, @blue25, @blue1200);

	.color-scheme(color, @gray1000, @gray400);

	padding: @lapAndUpSpacingM;
	display: grid;
	grid-template-columns: auto 1fr;
	gap: 4px;

	&-title {
		font-size: @fontSizeS;
		font-weight: 700;
		line-height: 18px;
		margin-bottom: @palmSpacingXS;

		@media @mediaQueryLapAndUp {
			margin-bottom: @lapAndUpSpacingXS;
		}
	}

	&-icon {
		.color-scheme(color, @blue600, @blue350);

		width: 16px;
		height: 16px;
	}

	&-message {
		font-size: @fontSizeXS;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		margin: 0 auto;
	}
}
