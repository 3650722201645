@import "../../../less/_variables/spacing.less";
@import "../../../less/_variables/media-queries.less";

@animation-speed: 100;
@slide-ease: cubic-bezier(0.86, 0, 0.07, 1);
@slide-duration: calc(400ms * 100 / @animation-speed);

.section-item {
	display: grid;
	grid-template-rows: 0fr 0fr;
	transition-timing-function: @slide-ease;
	transition-duration: 200ms, 200ms, @slide-duration;
	transition-property: opacity, box-shadow, grid-template-rows;

	button {
		cursor: pointer;
	}

	&-clickable {
		padding: 0;
		padding-inline: 0;
		display: block;
		appearance: unset;
		text-align: left;
		padding-block: unset;
		background-color: transparent;
		border: none;
		color: @gray1000;
		font-size: unset;
		font-family: inherit;
		line-height: inherit;
	}

	&-header {
		width: 100%;
		display: flex;
		gap: @lapAndUpSpacingS;

		&-container {
			flex: 1;
		}

		&-icon {
			margin-top: 6px; // to align with the text
		}
	}

	&-icon {
		font-size: 24px;
	}

	&-title {
		margin: 0;
		font-weight: 700 !important;
		flex: 1;
	}

	&-chevron {
		font-size: 24px;
		transition: rotate @slide-ease @slide-duration;
	}

	&-subline {
		margin-top: @lapAndUpSpacingXS;
	}

	&-cta {
		padding-left: @lapAndUpSpacingL;

		@media @mediaQueryPalmOnly {
			display: none;
		}
	}

	&-content-container {
		overflow: hidden;
	}

	&-content {
		padding-top: @palmSpacingM;

		@media @mediaQueryLapAndUp {
			padding-top: @lapAndUpSpacingM;
		}
	}

	&-expanded {
		grid-template-rows: 0fr 1fr;
		transition: grid-template-rows @slide-ease @slide-duration;

		.section-item-chevron {
			rotate: 180deg;
		}
	}

	&-show-more {
		margin-top: @lapAndUpSpacingM;
		display: block;
	}

	&-button-container {
		width: 100%;
		margin-top: @lapAndUpSpacingM;
		display: flex;
		gap: @lapAndUpSpacingS;

		& > * {
			flex: 1;

			@media @mediaQueryLapAndUp {
				width: unset;
				flex: unset;
			}
		}
	}

	&-with-cta {
		&.section-item {
			display: block;
		}

		& .section-item-clickable {
			display: flex;

			@media @mediaQueryPalmOnly {
				padding-right: @palmSpacingL;
			}

			@media @mediaQueryLapOnly {
				padding-right: @lapAndUpSpacingL;
			}
		}

		& .section-item-header-container {
			overflow: hidden;
		}

		& .section-item-subline {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&-carousel-item {
		width: unset !important; // override the width set by the carousel
		margin-right: @palmSpacingL;

		@media @mediaQueryLapAndUp {
			margin-right: @lapAndUpSpacingL;
		}
	}
}
