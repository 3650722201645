@import "../../../less/_variables/media-queries.less";

.empty-state {
	display: flex;

	.title {
		font-size: 20px;
		line-height: 30px;
		font-weight: 700 !important;
	}

	.description {
		line-height: 22px;
	}

	&.small {
		max-width: 328px;

		.image {
			width: 82.13px;
			height: 80px;
		}

		.text-container {
			padding-left: 16px;
			padding-bottom: 8px;
			display: flex;
			flex-direction: column;
			justify-content: end;
		}
	}

	&.large {
		max-width: 560px;

		@media @mediaQueryPalmOnly {
			max-width: 328px;
			flex-direction: column;
			align-items: center;
			text-align: center;
		}

		.image {
			width: 152.39px;
			height: 148px;
		}
		.text-container {
			padding-top: 10px;
			padding-left: 24px;
			display: flex;
			flex-direction: column;
			gap: 8px;

			@media @mediaQueryPalmOnly {
				padding-left: 0;
			}
		}

		.action-button {
			width: 139px;
			margin-top: 8px;

			@media @mediaQueryPalmOnly {
				width: 100%;
			}
		}
	}
}
