@import "../../../less/_variables/spacing.less";
@import "../../../less/_variables/media-queries.less";

.toast-container {
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: center;
	z-index: 1;

	top: @palmSpacingL;
	padding: 0 @palmSpacingL;

	@media @mediaQueryDeskAndUp {
		top: @lapAndUpSpacingL;
	}
}

.toast {
	opacity: inherit;
}
