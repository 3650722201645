@import "../../../less/_variables/color.less";
@import "../../../less/_variables/color-palette.less";
@import "../../../less/_variables/typography.less";
@import "../../../less/_variables/general.less";
@import "../../../less/_variables/media-queries.less";
@import "../../../less/_variables/borders.less";
@import "../../../less/_mixins/general.less";

.tabs-container {
	.tab-list {
		position: relative;
		display: inline-flex;
		flex-wrap: nowrap;
		-ms-overflow-style: -ms-autohiding-scrollbar;
		overflow-x: auto;
		min-width: 200px;
		width: 100%;

		// place scrollbar underneath element for chrome etc.
		padding-bottom: 15px;
		margin-bottom: -15px;

		// IE specific padding to make content and scrollbar behave
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			padding-bottom: 17px;
			margin-bottom: -17px;
		}

		&.border-bottom {
			border-bottom: none !important;

			&::after {
				content: "";
				flex: 1 1 auto;
				border-bottom: @borderWidthStandard solid @gray300;
			}

			.tab {
				border-bottom: @borderWidthStandard solid @gray300;
			}
		}

		.tab {
			@paddingX: (@lapAndUpSpacingM / 2);

			flex-shrink: 0;
			position: relative;
			display: inline-flex;
			text-align: center;
			align-items: center;
			cursor: pointer;
			line-height: 14px;
			justify-content: flex-end;
			border: none;
			background: transparent;
			white-space: nowrap;
			.color-scheme(color, @textColorLight, @gray700);
			font-weight: bold;
			font-family: @fontFamilyStandard-theme-cosma;
			gap: 4px;

			padding: 8px @paddingX 14px @paddingX;

			&--horizontal-view {
				flex-direction: row;

				i,
				span {
					font-size: 16px;
				}
				svg {
					width: 16px;
					height: 16px;
				}
			}

			&--vertical-view {
				flex-direction: column;

				i,
				span {
					font-size: 24px;
				}
				svg {
					width: 24px;
					height: 24px;
				}
			}

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}

			&:hover {
				.color-scheme(color, @textColorStandard, @white);
			}
			&[aria-selected="true"],
			&:active {
				.color-scheme(color, @textColorStandard, @white);
				&::after {
					position: absolute;
					bottom: -1px;
					left: @paddingX;
					width: calc(100% - 2 * @paddingX);
					content: "";
					height: 2px;
					background-color: @black1;
					display: inline-block;
					z-index: 1;
				}

				&:first-child {
					&::after {
						left: 0;
						width: calc(100% - @paddingX);
					}
				}

				&:last-child {
					&::after {
						width: calc(100% - @paddingX);
					}
				}

				&:only-child {
					&::after {
						left: 0;
						width: 100%;
					}
				}
			}
		}
	}
}
