@import "../../../less/_mixins/general.less";
@import "../../../less/_variables/general";
@import "../../../less/_variables/color-palette.less";

@tooltip-color: @gray1100;
@tooltip-color-light: @white;
@tooltip-color-dark: @gray1100;

.tooltip-container {
	position: relative;
	height: 100%;
	width: 100%;
}

.tooltip-target {
	height: 100%;
	width: 100%;
	line-height: 1;
}

.tooltip-container .tooltip {
	position: absolute;
	visibility: hidden;
	opacity: 0;
	z-index: 2;
	padding: 10px 16px;
	background: @tooltip-color;
	border-radius: 4px;

	&::after {
		content: "";
		position: absolute;

		border: 5px solid;
		transform-origin: 0 0;
		transform: rotate(-45deg);
	}
}

// Hide tooltip on touch devices
@media (hover: hover) {
	.tooltip-container:hover .tooltip {
		visibility: visible;
		opacity: 1;
	}
}

.tooltip.positioning-top {
	left: 50%;
	transform: translateX(-50%);
	bottom: calc(100% + 10px);
	&::after {
		border-color: transparent transparent @tooltip-color @tooltip-color;
		bottom: -9px;
	}
}

.tooltip.positioning-bottom {
	left: 50%;
	transform: translateX(-50%);
	top: calc(100% + 10px);
	&::after {
		border-color: @tooltip-color @tooltip-color transparent transparent;
		top: 1px;
	}
}

.tooltip.positioning-left {
	top: 50%;
	transform: translateY(-50%);
	right: calc(100% + 10px);
	&::after {
		border-color: transparent @tooltip-color @tooltip-color transparent;
		bottom: calc(50% - 10px);
		right: -2px;
	}
}

.tooltip.positioning-right {
	top: 50%;
	transform: translateY(-50%);
	left: calc(100% + 10px);
	&::after {
		border-color: @tooltip-color transparent transparent @tooltip-color;
		bottom: calc(50% - 10px);
		left: -6px;
	}
}

.tooltip.positioning-top.arrow-left,
.tooltip.positioning-bottom.arrow-left {
	left: calc(50% - 16px - 10px);
	transform: translateX(0);

	&::after {
		left: 17px;
	}
}

.tooltip.positioning-top.arrow-center,
.tooltip.positioning-bottom.arrow-center {
	&::after {
		left: calc(50% - 7px);
	}
}

.tooltip.positioning-top.arrow-right,
.tooltip.positioning-bottom.arrow-right {
	left: calc(50% + 23px);
	transform: translateX(-100%);

	&::after {
		right: 20px;
	}
}

// FCT-201
.tooltip.tooltip-variant {
	&.tooltip {
		padding: 0;
		background: unset;
		box-shadow: 0 1px 3px 0 fadeout(@black, 65%);

		&::before {
			content: "";
			width: 100%;
			display: block;
			height: 10px;
			position: absolute;
		}

		&::after {
			content: unset;
		}
	}

	.tooltip-content {
		background: @tooltip-color-dark;
		color: @white;

		padding: @palmSpacingS @palmSpacingM;
		position: relative;
		border-radius: 4px;

		&::after {
			content: "";
			position: absolute;
			border: 5px solid;
			transform-origin: 0 0;
			transform: rotate(-45deg);
			box-shadow: 0 1px 3px 0 fadeout(@black, 35%);
			z-index: -1;
		}
	}

	&.tooltip.positioning-top {
		left: 50%;
		transform: translateX(-50%);
		bottom: calc(100% + 10px);

		&::before {
			bottom: -10px;
		}

		.tooltip-content::after {
			border-color: transparent transparent @tooltip-color-dark @tooltip-color-dark;
			bottom: -9px;
		}
	}

	&.tooltip.positioning-bottom {
		left: 50%;
		transform: translateX(-50%);
		top: calc(100% + 10px);

		&::before {
			top: -10px;
		}

		.tooltip-content::after {
			border-color: @tooltip-color-dark @tooltip-color-dark transparent transparent;
			top: 1px;
		}
	}

	&.tooltip.positioning-left {
		top: 50%;
		transform: translateY(-50%);
		right: calc(100% + 10px);

		&::before {
			width: 10px;
			height: 100%;
			right: -10px;
		}

		.tooltip-content::after {
			border-color: transparent @tooltip-color-dark @tooltip-color-dark transparent;

			bottom: calc(50% - 10px);
			right: -2px;
		}
	}

	&.tooltip.positioning-right {
		top: 50%;
		transform: translateY(-50%);
		left: calc(100% + 10px);

		&::before {
			width: 10px;
			height: 100%;
			left: -10px;
		}

		.tooltip-content::after {
			border-color: @tooltip-color-dark transparent transparent @tooltip-color-dark;
			bottom: calc(50% - 10px);
			left: -6px;
		}
	}

	&.tooltip.positioning-top.arrow-left,
	&.tooltip.positioning-bottom.arrow-left {
		left: calc(50% - 16px - 10px);
		transform: translateX(0);

		.tooltip-content::after {
			left: 17px;
		}
	}

	&.tooltip.positioning-top.arrow-center,
	&.tooltip.positioning-bottom.arrow-center {
		.tooltip-content::after {
			left: calc(50% - 7px);
		}
	}

	&.tooltip.positioning-top.arrow-right,
	&.tooltip.positioning-bottom.arrow-right {
		left: calc(50% + 23px);
		transform: translateX(-100%);

		.tooltip-content::after {
			right: 20px;
		}
	}
}
