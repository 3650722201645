// --- Background ---

// - Solid Colors

.background {
	background-color: @gray9 !important;
} // standard, non-functional background

.background-white {
	background-color: @white !important;
}
.background-brandblue {
	background-color: @blue1 !important;
}
.background-brandorange {
	background-color: @orange4 !important;
}

.background-confirm {
	background-color: @signalColorConfirmLight !important;
}
.background-info {
	background-color: @signalColorInfoLight !important;
}
.background-warning {
	background-color: @signalColorWarningLight !important;
}
.background-error {
	background-color: @signalColorErrorLight !important;
}
