// --- Advertisements ---

// - Hide/Show Ad Containers -
.ad-superbanner,
.ad-skyscraper--right,
.ad-skyscraper--left {
	display: none; // no ads on palm devices
}
@media @mediaQueryLapAndUp {
	.has-ad-superbanner {
		.ad-superbanner {
			display: block;
		}
	}
}
@media @mediaQueryCanShowPartialSkyscraperAndUp {
	.has-ad-skyscraper--right {
		.ad-skyscraper--right {
			display: block;
		}
	}
}
@media @mediaQueryCanShowPartialDoubleSkyscraperAndUp {
	.has-ad-skyscraper--left {
		.ad-skyscraper--left {
			display: block;
		}
	}
}

// Page wrapper serves as positioning reference
.page-wrapper--with-ads {
	position: relative;
}

// In hockey stick configurations, the skyscraper container is loaded into the super banner container and needs
// to be individually hidden on smaller devices to keep it from overlapping the slide-in menu.
#FEM_WALL_DIV {
	display: none;
}
@media @mediaQueryCanShowPartialSkyscraperAndUp {
	#FEM_WALL_DIV {
		display: block;
	}
}

// - Stacking Hierarchy for Header, Super Banner and Skyscrapers -
@media @mediaQueryLapAndUp {
	.ad-superbanner,
	.ad-skyscraper--right,
	.ad-skyscraper--left {
		position: absolute;
	}
	.ad-superbanner {
		z-index: (@fixToTopZIndex + 1);
	}
	.ad-skyscraper--right,
	.ad-skyscraper--left {
		z-index: (@fixToTopZIndex - 1);
	}
}

// - Super Banner -
@media @mediaQueryLapAndUp {
	.has-ad-superbanner {
		.page-wrapper--with-ads {
			margin-top: @superbannerPlacementHeight; // top spacing for super banner
		}
	}

	.ad-superbanner {
		top: -(@superbannerPlacementHeight + @pageBorderWidth); // pull up to top
		right: 0;
		height: @superbannerPlacementHeight;
	}
}
@media @mediaQueryDeskAndUp {
	.ad-superbanner {
		right: -(@spacingBetweenSkyscraperAndContent + @pageBorderWidth); // allows for horizontal spacing on skyscraper in hockey stick placements
	}
}

// - Skyscraper -
@media @mediaQueryCanShowPartialSkyscraperAndUp {
	.ad-skyscraper--right,
	.ad-skyscraper--left {
		top: (@headerHeightDesk + @topNavigationHeight); // should sit right underneath header bar
		width: @skyscraperWidth;
	}

	.ad-skyscraper--right {
		right: -(@skyscraperPlacementWidth + @pageBorderWidth);
	}

	.ad-skyscraper--left {
		left: -(@skyscraperPlacementWidth + @pageBorderWidth);
	}
}

@media @mediaQueryCanShowSingleSkyscraper {
	.has-ad-skyscraper--right {
		.page-wrapper--with-ads {
			margin-right: @skyscraperPlacementWidth; // space for right skyscraper
			width: auto;
			min-width: @pageWidthMin; // reserve minimum content width regardless of ads

			// The default value "auto" in combination with a minimum width and an absolute right margin may result in
			// negative left margin values in some browsers, which would pull part of the content out of bounds.
			// To prevent this, the page wrapper gets zero left margin in layouts with insufficient space for the right margin.
			margin-left: 0;
		}
	}
}

@media @mediaQueryCanShowSingleSkyscraperWithUncompressedPage {
	.has-ad-skyscraper--right {
		.page-wrapper--with-ads {
			margin-left: auto;
		}
	}
}
