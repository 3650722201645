@import "../../../less/_mixins/typography.less";
@import "../../../less/_mixins/general.less";
@import "../../../less/_variables/general.less";
@import "../../../less/helpers.less";

// text styles
.text-heading-0 {
	.text(heading-0) !important;
}

.text-heading-1 {
	.text(heading-1) !important;
}

.text-heading-2 {
	.text(heading-2) !important;
}

.text-heading-3 {
	.text(heading-3) !important;
}

.text-heading-4 {
	.text(heading-4) !important;
}

.text-heading-5,
.text-heading-6 {
	.text(heading-5) !important;
}

.text-hint {
	.text(hint) !important;
}

.text-paragraph {
	.text(paragraph) !important;
}

.text-paragraph-tabular {
	.text(paragraph-tabular) !important;
}

// DEPRECATED START
// Please use .text-[NAME] utility classes

.font-h1 {
	.text(heading-1) !important;
}

.font-h2 {
	.text(heading-2) !important;
}

.font-h3 {
	.text(heading-3) !important;
}

.font-h4 {
	.text(heading-4) !important;
}

.font-h5,
.font-h6 {
	.text(heading-5) !important;
}

.font-h1,
.font-h2,
.font-h3 {
	font-weight: 700;
}

.font-h4,
.font-h5,
.font-h6 {
	font-weight: 600;
}

.font-h1,
.font-h2,
.font-h3,
.font-h4,
.font-h5,
.font-h6 {
	margin-bottom: @headlineBottomMarginStandard;
}

// DEPRECATED END

// font weight
.font-light {
	font-weight: 300 !important;
}

.font-normal {
	font-weight: 400 !important;
}

.font-semibold {
	font-weight: 600 !important;
}

.font-bold {
	font-weight: 800 !important;
}

// font style
.font-italic {
	font-style: italic !important;
}

.font-strike {
	text-decoration: line-through !important;
}

// text transform
.font-uppercase {
	text-transform: uppercase !important;
}

.font-capitalized {
	text-transform: capitalize !important;
}

// font colors
.font-brand,
.font-brand-primary,
.font-brandorange {
	color: @color-primary-theme-cosma !important;
}

.font-error {
	color: @color-validation-error-theme-cosma !important;
}

.font-confirm {
	color: @color-validation-positive-theme-cosma !important;
}

.font-info {
	color: @color-validation-info-theme-cosma !important;
}

.font-warning {
	color: @color-validation-warning-theme-cosma !important;
}

.font-white {
	color: @white !important;
}

.font-regular {
	color: @textColorStandard !important;
}

.font-lightgray {
	color: @textColorLight !important;
}

// wrapping
.font-nowrap {
	white-space: nowrap !important;
}

// break words
.font-break-all {
	word-break: break-all;
}

.font-ellipsis {
	.font-ellipsis();
}

.font-highlight {
	.font-highlight();
}

.font-tabular {
	.font-tabular();
}

.font-hint {
	font-size: @fontSizeXS;
}

// alignment
.font-center {
	text-align: center !important;
}

.font-left {
	text-align: left !important;
}

.font-right {
	text-align: right !important;
}

// line-heights

.font-line-xs,
.font-line-s {
	line-height: @lineHeightS !important;
}

.font-line-standard {
	line-height: @lineHeightStandard !important;
}
