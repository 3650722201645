@import "../../../less/_mixins/general.less";

@loaderSizeSmall:           24px;
@loaderSizeMedium:          48px;
@loaderSizeLarge:           96px;

/* Safari */
@-webkit-keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loader {
	background-image: url(https://www.static-immobilienscout24.de/fro/is24-loader/0.0.2/loader.svg);
	animation: rotate 1s linear infinite;
	height: @loaderSizeMedium;
	width: @loaderSizeMedium;
	background-size: @loaderSizeMedium;

	&.loader-small {
		height: @loaderSizeSmall;
		width: @loaderSizeSmall;
		background-size: @loaderSizeSmall;
	}
	&.loader-medium {
		height: @loaderSizeMedium;
		width: @loaderSizeMedium;
		background-size: @loaderSizeMedium;
	}
	&.loader-large {
		height: @loaderSizeLarge;
		width: @loaderSizeLarge;
		background-size: @loaderSizeLarge;
	}
}
