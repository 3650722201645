@import "../../../less/_variables/typography.less";
@import "../../../less/_variables/spacing.less";
@import "../../../less/_mixins/general.less";

.dropdown {
	display: inline-block;
	position: relative;
	font-family: @fontFamilyStandard-theme-cosma;

	&-list {
		display: none;
		padding: @lapAndUpSpacingM 0;
		position: absolute;
		left: 0;
		top: calc(100% + 12px);
		border: solid @gray200 1px;
		box-shadow: 0px 2px 4px 0px #00000026;
		border-radius: 8px;
		z-index: 10;
	}

	&-right {
		left: auto;
		right: 0;
	}

	&-item {
		height: 56px;
		padding-left: @lapAndUpSpacingXL;
		padding-right: @lapAndUpSpacingXXL;
		font-size: @fontSizeS;
		line-height: @lineHeightForFontSizeStandard;
		display: flex;
		align-items: center;
		gap: @lapAndUpSpacingM;
		cursor: pointer;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		&:hover:not(&-disabled),
		&:focus:not(&-disabled),
		&:active:not(&-disabled) {
			background-color: @gray200;
		}

		&-disabled {
			pointer-events: none;
			color: @gray650;
		}

		&-danger {
			color: @red500;
		}
	}

	&-icon {
		font-size: 24px;
	}

	&-open {
		.dropdown-list {
			display: block;
		}
	}

	&-condensed {
		.dropdown-item {
			height: 40px;
		}
	}
}
